
export interface AppEnv {
  applicationName: string;
  versionName: string;
  releaseDate: string;
  envName: string;
  apiUrl: string;
  useMockApi: boolean;
  buildNumber: string;
  mesaDemo: boolean;
}

export const ENV: AppEnv =  {
  applicationName: "Mesa OnTime",
  versionName: process.env.REACT_APP_VERSION?.trim() || "vx.x.x",
  releaseDate: process.env.REACT_APP_RELEASE_DATE?.trim() || "RELEASE_DATE_NOT_GIVEN",
  buildNumber: process.env.REACT_APP_BUILD_NUMBER?.trim() || "BUILD_NUMBER_NOT_GIVEN",
  envName: process.env.REACT_APP_ENV?.trim() || "ENV_NOT_GIVEN",
  apiUrl: process.env.REACT_APP_API_URL?.trim() || "API_URL_NOT_GIVEN",
  useMockApi: !!(process.env.REACT_APP_MOCK_API?.trim().match(/true/i)) || false,
  mesaDemo: process.env.REACT_APP_ENV === "demo"
}

console.info(`
-----------------------------------
--------|--|||||||||||||--|--------
-------|||--|||||||||||--|||-------
------|||||--|||||||||--|||||------
-----|||||||--|||||||--|||||||-----
----|||||||||--|||||--|||||||||----
---|||||||||||--|||--|||||||||||---
--|||||||||||||--|--|||||||||||||--
-----------------------------------
--------------m-e-s-a--------------
-----------------------------------
${ENV.applicationName} UI ${ENV.versionName}
Release: ${ENV.releaseDate}
Environment: ${ENV.envName}
Build #: ${ENV.buildNumber}
`)

if (ENV.envName !== "production") {
  console.log(`
  API Url: ${ENV.apiUrl}
  In Demo: ${ENV.mesaDemo}
  `)
}
