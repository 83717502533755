import { PayloadAction, PayloadActionCreator } from '@reduxjs/toolkit'
import { takeLeading, all, call, put } from 'redux-saga/effects'
import { downloadCSV, createDatedFilename } from "../../mesacloud/corejs"

import { FlagStatusAndTypePayload } from '../flags/flagsDomain'
import { StudentIDAndNamePayload } from '../student/studentDomain'
import { errorSlice } from '../error/errorSlice'
import { fileExportSlice } from './fileExportSlice'
import * as exportSagaActions from './fileExportSagaActions'

import { OnTimeAPI } from '../../lib/api'

const createGenericExport = (
  requestAction: PayloadActionCreator,
  completeAction: PayloadActionCreator,
  getUrl: string,
  fileName: string,
  errorMessage: string,
) => {
  return (function*(){
    try {
      yield put(requestAction())
      const result = yield call(() => {
        return OnTimeAPI.get(getUrl, { }, "header1,header2,header3\nvalue1,value2,value3")
      })
      result && result.data && downloadCSV(result.data, createDatedFilename(fileName + "_", "csv"))
    } catch(e){
      yield put(errorSlice.actions.displayError({ errorMessage: `${errorMessage}: ${e}` }))
    } finally {
      yield put(completeAction())
    }
  })
}

const exportCoursesSaga = createGenericExport(
  fileExportSlice.actions.exportCoursesRequested,
  fileExportSlice.actions.exportCoursesComplete,
  "/api/gradplan/download/alltranscripts",
  "Mesa_Courses",
  "Error getting Courses (transcripts) export"
)

const exportOnTrackSaga = createGenericExport(
  fileExportSlice.actions.exportOnTrackRequested,
  fileExportSlice.actions.exportOnTrackComplete,
  "/api/export/ontrack",
  "Mesa_Students_On_Track",
  "Error getting on track export"
)

const exportOffTrackSaga = createGenericExport(
  fileExportSlice.actions.exportOffTrackRequested,
  fileExportSlice.actions.exportOffTrackComplete,
  "/api/export/offtrack",
  "Mesa_Off_Track",
  "Error getting off track export"
)

const exportOverallRiskSaga = createGenericExport(
  fileExportSlice.actions.exportOverallRiskRequested,
  fileExportSlice.actions.exportOverallRiskComplete,
  "/api/overallexport",
  "Mesa_Overall_Risk_Assessment",
  "Error getting Overall Risk export"
)

const exportYE9RiskSaga = createGenericExport(
  fileExportSlice.actions.exportYE9RiskRequested,
  fileExportSlice.actions.exportYE9RiskComplete,
  "/api/cohortexport",
  "Mesa_Risk_Assessment_By_YE9",
  "Error getting YE9 Risk export"
)

function* exportFlagsSaga({ payload }: PayloadAction<FlagStatusAndTypePayload>){
  try {
    yield put(fileExportSlice.actions.exportFlagsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get('/api/flags/csvexport', { params: payload }, "header1,header2,header3\nvalue1,value2,value3")
    })
    if(result.data){
      // const data = new TextDecoder("utf-8").decode(result.data)
      downloadCSV(result.data, createDatedFilename("Mesa_Flags", "csv"))
    }
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error exporting flags: ${e}` }))
  } finally {
    yield put(fileExportSlice.actions.exportFlagsComplete())
  }
}

function* exportStudentTranscriptSaga({ payload: { studentId, studentName } }: PayloadAction<StudentIDAndNamePayload>){
  try {
    yield put(fileExportSlice.actions.exportStudentTranscriptRequested())
    const result = yield call(() => {
      return OnTimeAPI.get('/api/gradplan/transcript', { params: { studentId } }, "header1,header2,header3\nvalue1,value2,value3")
    })
    if(result.data){
      const studentFileName = studentName.replace(/[\s]/g, '-').replace(/[^A-Za-z0-9-]/g, '') || studentId
      downloadCSV(result.data, createDatedFilename(`${studentFileName}-transcript-records`, "csv"))
    }
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error exporting student transcript: ${e}` }))
  } finally {
    yield put(fileExportSlice.actions.exportStudentTranscriptComplete())
  }
}

export function* rootFileExportSaga(){
  yield all([
    takeLeading(exportSagaActions.fetchCoursesExport.type, exportCoursesSaga),
    takeLeading(exportSagaActions.fetchOnTrackExport.type, exportOnTrackSaga),
    takeLeading(exportSagaActions.fetchOffTrackExport.type, exportOffTrackSaga),
    takeLeading(exportSagaActions.fetchOverallRiskExport.type, exportOverallRiskSaga),
    takeLeading(exportSagaActions.fetchYE9RiskExport.type, exportYE9RiskSaga),
    takeLeading(exportSagaActions.fetchFlagsExport.type, exportFlagsSaga),
    takeLeading(exportSagaActions.fetchStudentTranscriptExport.type, exportStudentTranscriptSaga)
  ])
}
