
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from "../../../../mesacloud/react-framework"

import { StudentCTE } from '../../../../store/student/studentDomain'
import { useSelector, studentSagaActions } from '../../../../store'

import StudentMainCTEHeader from './StudentMainCTEHeader'
import StudentMainCTECluster from './StudentMainCTECluster'
import StudentMainCTEProgress from './StudentMainCTEProgress'
import { useStudentPageContext } from '../../studentPageContext'

const StudentMainCTE = () => {

  const [selectedCTE, setSelectedCTE] = useState<StudentCTE | null>(null)

  const [selectedCluster, setSelectedCluster] = useState("")

  const { studentId } = useStudentPageContext()

  const dispatch = useDispatch()

  // This code takes the state and gets the student, then
  // from the student gets the studentCTEs defined in src/store/student/studentSlice.ts
  // This process is called destructuring and is native to javascript/typescript.
  const {
    studentCTEs,
  } = useSelector(({ student }) => student)

  useEffect(() => {
    if(Array.isArray(studentCTEs) && studentCTEs.length > 0){
      const ctes = [...studentCTEs]
      ctes.sort((a,b) => a?.sequence > b?.sequence ? 1 : -1)
      if(!selectedCTE){
        setSelectedCTE(ctes[0])
      }
    } else {
      setSelectedCTE(null)
    }
    // eslint-disable-next-line
  }, [studentCTEs])

  useEffect(() => {
    if(studentId){
      dispatch(studentSagaActions.fetchStudentCTEs({ studentId }))
    }
  }, [studentId, dispatch])

  return (
    <MesaUI.Container className="student__main__gp">
      <StudentMainCTEHeader 
        selectedCTE={selectedCTE} 
        setSelectedCTE={setSelectedCTE}
      />
      <MesaUI.FlexRow className="student__main__gp__content">
        <MesaUI.FlexCol col={1}>
          <StudentMainCTEProgress selectedCTE={selectedCTE} setSelectedCluster={setSelectedCluster}/>
        </MesaUI.FlexCol>
        <MesaUI.FlexCol col={2}>
          <MesaUI.Container className="student__main__subjects-wrapper" paddingType={{ bottom: "lg" }}>
            {selectedCTE && selectedCTE.clusters?.map((cluster, i) => (
              <StudentMainCTECluster
                key={`student-main-cte-cluster-${i}`}
                cluster={cluster}
                selected={cluster?.name === selectedCluster}
              />
            ))}
          </MesaUI.Container>
        </MesaUI.FlexCol>
      </MesaUI.FlexRow>
    </MesaUI.Container>
  )
}

export default StudentMainCTE
