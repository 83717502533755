
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosRequestHeaders
} from 'axios'

const createMockResponse =  <ResultType>(url: string, mockData: ResultType): Promise<AxiosResponse<ResultType>> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve({
      data: mockData,
      status: 200,
      statusText: "OK",
      headers: {},
      config: {
        headers: {} as AxiosRequestHeaders
      },
      request: { url }
    }), Math.round((Math.random() * 2)) * 1000 + 100)
  })
} 

const parseAndReplacePathParams = (url: string, pathParams?: { [key: string]: string }): string => {

  const urlPathParams = url.match(/{{[^{}]+}}/g)

  if(urlPathParams){
    if(!pathParams || typeof pathParams !== "object"){
      throw new Error(`API.get URL ${url} expects pathParams: { [string]: string } in config object, but received none`)
    }
    return urlPathParams.reduce((_url, param) => {
      const keyname = param.replace(/[{}]/g,'')
      if(!pathParams.hasOwnProperty(keyname)){
        throw new Error(`API.get URL ${url} expects pathParam ${param} but did not receive a value in config.pathParams`)
      }
      return _url.replace(param, pathParams[keyname])
    }, url)
  }

  return url
}

type RequestHandler = (config: InternalAxiosRequestConfig<any>) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>
type ResponseHandler = (res: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>
type ErrorHandler = (err: AxiosError) => any

type ResponseMiddleware = ResponseHandler | [ResponseHandler, ErrorHandler]
type RequestMiddleware = RequestHandler | [RequestHandler, ErrorHandler]

export interface MesaAPIInterface {
  setAsMock: () => void;
  addRequestMiddleware: (middleware: RequestMiddleware) => void;
  addResponseMiddleware: (middleware: ResponseMiddleware) => void;
  get: <ResultType>(url: string, config: { pathParams?: { [key: string]: string } } & AxiosRequestConfig) => Promise<AxiosResponse<ResultType>>;
  post: <ResultType, PostDataType = { [keyname: string]: any }>(url: string, data: PostDataType, config: { pathParams?: { [key: string]: string } } & AxiosRequestConfig, mockData?: ResultType) => Promise<AxiosResponse<ResultType>>;
  put: <ResultType, PutDataType = { [keyname: string]: any }>(url: string, data: PutDataType, config: { pathParams?: { [key: string]: string } } & AxiosRequestConfig, mockData?: ResultType) => Promise<AxiosResponse<ResultType>>;
  delete: <ResultType>(url: string, config: { pathParams?: { [key: string]: string } } & AxiosRequestConfig) => Promise<AxiosResponse<ResultType>>;
}

export class MesaAPI implements MesaAPIInterface {

  private axiosInstance: AxiosInstance
  private isMock = false

  constructor(
    baseAxioConfig: AxiosRequestConfig,
    options?: {
      requestMiddleware?: RequestMiddleware;
      responseMiddleware?: ResponseMiddleware;
    }
  ) {

    const axiosInstance = axios.create(baseAxioConfig)

    if(options){
      const { requestMiddleware, responseMiddleware } = options

      if(typeof requestMiddleware === "function"){
        axiosInstance.interceptors.request.use(requestMiddleware)
      } else if(requestMiddleware !== undefined) {
        axiosInstance.interceptors.request.use(...requestMiddleware)
      }

      if(typeof responseMiddleware === "function"){
        axiosInstance.interceptors.response.use(responseMiddleware)
      } else if(responseMiddleware !== undefined) {
        axiosInstance.interceptors.response.use(...responseMiddleware)
      }
    }
    
    this.axiosInstance = axiosInstance
  }

  public setAsMock(){
    this.isMock = true
  }

  public addRequestMiddleware(middleware: RequestMiddleware){
    if(typeof middleware === "function"){
      this.axiosInstance.interceptors.request.use(middleware)
    } else if(middleware !== undefined) {
      this.axiosInstance.interceptors.request.use(...middleware)
    }
  }

  public addResponseMiddleware(middleware: ResponseMiddleware){
    if(typeof middleware === "function"){
      this.axiosInstance.interceptors.response.use(middleware)
    } else if(middleware !== undefined) {
      this.axiosInstance.interceptors.response.use(...middleware)
    }
  }

  private handleMockData(url: string, mockData: any){
    if(this.isMock){
      if(mockData === undefined){
        throw new Error(`REACT_APP_MOCK_API=true but mock data not passed for API call ${url}`)
      }
      return createMockResponse(url, mockData)
    }
  }

  public get<ResultType>(url: string, config?: { pathParams?: { [key: string]: string } } & AxiosRequestConfig, mockData?: ResultType): Promise<AxiosResponse<ResultType>> {
    const finalUrl = parseAndReplacePathParams(url, config && config.pathParams)
    const mockResult = this.handleMockData(finalUrl, mockData)
    return mockResult === undefined ? this.axiosInstance.get(finalUrl, config) : mockResult
  }

  public post<ResultType, PostDataType = { [keyname: string]: any }>(url: string, data: PostDataType, config?: { pathParams?: { [key: string]: string } } & AxiosRequestConfig, mockData?: ResultType): Promise<AxiosResponse<ResultType>> {
    const finalUrl = parseAndReplacePathParams(url, config && config.pathParams)
    const mockResult = this.handleMockData(finalUrl, mockData)
    return mockResult === undefined ? this.axiosInstance.post(finalUrl, data, config) : mockResult
  }

  public put<ResultType, PutDataType = { [keyname: string]: any }>(url: string, data: PutDataType, config?: { pathParams?: { [key: string]: string } } & AxiosRequestConfig, mockData?: ResultType): Promise<AxiosResponse<ResultType>> {
    const finalUrl = parseAndReplacePathParams(url, config && config.pathParams)
    const mockResult = this.handleMockData(finalUrl, mockData)
    return mockResult === undefined ? this.axiosInstance.put(finalUrl, data, config) : mockResult
  }

  public delete<ResultType>(url: string, config?: { pathParams?: { [key: string]: string } } & AxiosRequestConfig, mockData?: ResultType): Promise<AxiosResponse<ResultType>> {
    const finalUrl = parseAndReplacePathParams(url, config && config.pathParams)
    const mockResult = this.handleMockData(finalUrl, mockData)
    return mockResult === undefined ? this.axiosInstance.delete(finalUrl, config) : mockResult
  }
}
