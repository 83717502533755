import createClassName from 'classnames'
import { capitalize } from '../../../corejs'

import { MarginOrPaddingProp } from '../propsInterfaces'

type AttributeType = "margin" | "padding"

type StyleResult = [Array<string>, { [key: string]: string | number }]

const SPECIAL_TYPES = ["xl", "lg", "md", "sm", "xs"]
const DIRECTIONS = ["top", "right", "bottom", "left"]

const createMarginOrPaddingStyleFromString = (type: AttributeType, val: string): StyleResult => {
  if(SPECIAL_TYPES.includes(val)){
    return [[`${type}--${val}`], {}]
  } else {
    return [[],{ [type]: val }]
  }
}

const createMarginOrPaddingStyleFromObject = (type: AttributeType, val: { 
  top?: string,
  bottom?: string,
  left?: string,
  right?: string,
}): StyleResult => {
  const classNames: Array<string> = []
  const style: { [key: string]: string | number } = {}
  Object.entries(val).forEach(([key, mopType]: [string, string]) => {
    if(DIRECTIONS.includes(key)){
      const direction = key
      if(SPECIAL_TYPES.includes(mopType)){
        classNames.push(`${type}-${direction}--${mopType}`)
      } else {
        style[`${type}${capitalize(direction)}`] = mopType
      }
    }
  })
  return [classNames, style]
}

const createMarginOrPaddingStyleFromArr = (type: AttributeType, val: Array<string>): StyleResult => {
  const limitedVal = val.slice(0,DIRECTIONS.length)

  const classNames: Array<string> = []
  const style: { [key: string]: string | number } = {}
  limitedVal.forEach((mopType,i) => {
    const direction = DIRECTIONS[i]
    if(SPECIAL_TYPES.includes(mopType)){
      classNames.push(`${type}-${direction}--${mopType}`)
    } else {
      style[`${type}${capitalize(direction)}`] = mopType
    }
  })
  return [classNames, style]
}

const createMarginOrPaddingStyle = (
  type: AttributeType, 
  marginOrPaddingType?: MarginOrPaddingProp
): StyleResult => {
  switch(typeof marginOrPaddingType){
    case "string":
      return createMarginOrPaddingStyleFromString(type, marginOrPaddingType)
    case "object":
      if(Array.isArray(marginOrPaddingType)){
        return createMarginOrPaddingStyleFromArr(type, marginOrPaddingType)
      } else if(marginOrPaddingType !== null){
        return createMarginOrPaddingStyleFromObject(type, marginOrPaddingType)
      }
  }
  return [[], {}]
}

export const useMarginAndPaddingStyle = (options: {
  margin?: MarginOrPaddingProp,
  padding?: MarginOrPaddingProp
}): StyleResult => {

  const [marginClassNames, marginStyleObj] = createMarginOrPaddingStyle("margin", options.margin)
  const [paddingClassNames, paddingStyleObj] = createMarginOrPaddingStyle("padding", options.padding)

  return [marginClassNames.concat(paddingClassNames), { ...marginStyleObj, ...paddingStyleObj }]
}
