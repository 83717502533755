
import React, { useMemo } from 'react'
import { createPortal } from 'react-dom'

export const useRootPortal = (render: React.ReactElement, rootElId: string): React.ReactPortal => {

  const rootEl: HTMLElement = useMemo(() => {
    const existingEl = document.getElementById(rootElId)

    if(!existingEl){
      const fixedEl = document.createElement('div')
      fixedEl.id = rootElId
      document.body.prepend(fixedEl)
      return fixedEl
    }

    return existingEl
  }, [rootElId])

  return createPortal(render, rootEl)
}