
import React, { useState, useEffect } from 'react'
import MesaUI, { useToggle } from '../../../mesacloud/react-framework'

import { useSelector } from '../../../store'

import PanelGroupListSelectedStudents from './PanelGroupListSelectedStudents'
import PanelGroupListSelectedAdd from './PanelGroupListSelectedAdd'

const PanelGroupListSelected = () => {

  const [view, setView] = useState<"students" | "add">("students")
  const [viewSingleAddOnly, toggleViewSingleAddOnly] = useToggle(false)
  const [groupPage, setGroupPage] = useState(0)

  const { panelIsOpen } = useSelector(({ view }) => view)
  const { selectedGroupId } = useSelector(({ groups }) => groups)

  useEffect(() => {
    setView("students")
  }, [panelIsOpen])

  useEffect(() => {
    toggleViewSingleAddOnly(false)
    setGroupPage(0)
  // eslint-disable-next-line
  }, [selectedGroupId])

  return (
    <>
      <MesaUI.Container className="panel-groups__view-switch">
        <MesaUI.Container onClick={() => setView("students")} className={{ "panel-groups__view-switch__option": true, active: view === "students"}}>
          Students
        </MesaUI.Container>
        <MesaUI.Container onClick={() => setView("add")} className={{ "panel-groups__view-switch__option": true, active: view === "add"}}>
          Add
        </MesaUI.Container>
      </MesaUI.Container>
      {(() => {
        switch(view){
          case "students":
            return (
              <PanelGroupListSelectedStudents 
                groupPage={groupPage} 
                setGroupPage={setGroupPage} 
                viewSingleAddOnly={viewSingleAddOnly} 
                toggleViewSingleAddOnly={toggleViewSingleAddOnly}
              />
            )
          case "add":
            return <PanelGroupListSelectedAdd/>
          default:
            return <></>
        }
      })()}
    </>
  )
}

export default PanelGroupListSelected
