import React, { ReactNode } from 'react'

import MesaUI from '../mesacloud/react-framework'

const LoadingPage = ({ children, displayLoader }: { displayLoader?: boolean; children?: ReactNode }) => {
  return (
    <div className="blocker-page">
      <MesaUI.TopographyBanner className="blocker-page__background"/>
      <div className="blocker-page__content">
        <MesaUI.Card className="blocker-page__content__card">
          <MesaUI.CardContent>
            <MesaUI.Container className="blocker-page__content__card-content" paddingType="lg">
              <MesaUI.Logo className="blocker-page__content__logo" logoType="mesa"/>
              <MesaUI.Container className="blocker-page__content__message-container" marginType={{ top: "lg" }}>
                <div className="blocker-page__content__message">{children}</div>
                {displayLoader && <MesaUI.LoadingRing seafoam/>}
              </MesaUI.Container>
            </MesaUI.Container>
          </MesaUI.CardContent>
        </MesaUI.Card>
      </div>
    </div>
  )
}

export default LoadingPage
