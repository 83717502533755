
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps,
} from '../propsInterfaces'

interface TableRowPropTypes extends
  HasEditableClassName,
  MaybeHasChildren, 
  ExtendableProps {
  header?: boolean | null;
}

const TableRow = ({
  children,
  className,
  overrideDefaultClassName,
  header,
  ...props
}: TableRowPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: { "table__row": !header, "table__row--header": header },
    additionalClassName: className, 
    overrideDefaultClassName,
  })

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  )
}

export default TableRow