
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  HasConfigurableColors,
  MaybeHasChildren,
  HasMarginAndPaddingOptions,
  ExtendableProps
} from '../propsInterfaces'

interface CollapsibleListCardItemPropTypes extends
  HasEditableClassName,
  MaybeHasChildren,
  HasConfigurableColors,
  HasMarginAndPaddingOptions,
  ExtendableProps {
}

const CollapsibleListCardItem = ({
  className,
  overrideDefaultClassName,
  children,
  backgroundColor,
  borderColor,
  fontColor,
  marginType,
  paddingType,
  ...props
}: CollapsibleListCardItemPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "collapsible-list-card__item", 
    additionalClassName: className, 
    overrideDefaultClassName,
    backgroundColor,
    borderColor,
    fontColor,
    marginType,
    paddingType
  })

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  )
}

export default CollapsibleListCardItem