import { useEffect } from "react";

/*
  useDocumentTitle

  Usage: useDocumentTitle(docTitle: string)

  Sets document title on component mount
*/

export const useDocumentTitle = (docTitle: string) => {
  useEffect(() => {
    if (document && document.title !== undefined) {
      document.title = docTitle || "Mesa";
    }
  }, [docTitle]);
};
