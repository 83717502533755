
import React from 'react'

import {
  HasChildren,
  HasEditableClassName,
  HasLoadingMaskOption
} from '../../propsInterfaces'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

interface MainPropTypes extends
  HasChildren,
  HasEditableClassName,
  HasLoadingMaskOption {
  withLeftnav?: boolean | null;
  withRightbar?: boolean | null;
  withHeader?: boolean | null;
  withFooter?: boolean | null;
  withFullShell?: boolean | null;
}

const Main = ({
  children,
  className,
  overrideDefaultClassName,
  
  withLeftnav,
  withRightbar,
  withHeader,
  withFooter,
  withFullShell
}: MainPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: {
      "main": !withFullShell && !withLeftnav && !withRightbar && !withHeader && !withFooter,
      "main--full-shell": withFullShell,
      "main--with-leftnav": withLeftnav && !withFullShell,
      "main--with-rightbar": withRightbar && !withFullShell,
      "main--with-header": withHeader && !withFullShell,
      "main--with-footer": withFooter && !withFullShell,
    }, 
    additionalClassName: className, 
    overrideDefaultClassName
  })

  return (
    <main  className={finalClassName}>
      {children}
    </main>
  )
} 

export default Main