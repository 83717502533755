
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI, { useDocumentTitle } from "../../mesacloud/react-framework"

import { useRedirectRoles, useCloseNotesOnFirstLoad } from '../../hooks'
import { UserRoleEnum } from '../../lib/enums'
import { PanelViewEnum } from '../../lib/enums'

import { StudentGroup } from '../../store/groups/groupsDomain'
import { useSelector, groupsSagaActions, viewActions } from '../../store'

import GroupsSavedGroup from './GroupsSavedGroup'
import GroupsDetailModal from './GroupsDetailsModal'
import CreateGroupModal from './createGroup/CreateGroup'
import { MesaColorEnum } from '../../mesacloud/corejs'

const Groups = () => {

  const [showDetails, setShowDetails] = useState(false)
  const [showCreateGroup, setShowCreateGroup] = useState(false)
  const [groupForEdit, setGroupForEdit] = useState<StudentGroup | null>(null)
  
  useDocumentTitle("Mesa OnTime: Groups")
  useRedirectRoles([UserRoleEnum.STUDENT])
  useCloseNotesOnFirstLoad()

  const dispatch = useDispatch()

  const {
    isLoadingStudentGroups,
    isLoadingGroupDetails,
    isDeletingGroup,
    isLoadingCampusOptions,
    campusOptions,
    studentGroups,
    groupDetails
  } = useSelector(({ groups }) => groups) 

  const isLoading = isDeletingGroup || isLoadingStudentGroups || isLoadingCampusOptions

  useEffect(() => {
    if(!studentGroups || studentGroups.length === 0){
      dispatch(groupsSagaActions.fetchStudentGroups())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(!campusOptions || campusOptions.length === 0){
      dispatch(groupsSagaActions.fetchCampusOptions())
    }
    // eslint-disable-next-line
  }, [])

  const viewGroup = (group: StudentGroup) => {
    dispatch(groupsSagaActions.fetchGroupList({ groupId: group.systemUserGroupId, groupName: group.groupName, page: 0, isSingleAddOnly: false }))
    dispatch(viewActions.setPanelView({ view: PanelViewEnum.GROUP_LIST }))
    dispatch(viewActions.openPanel())
  }

  const editGroup = (group: StudentGroup) => { 
    setShowCreateGroup(true)
    setGroupForEdit(group)
  }

  const viewGroupDetails = (group: StudentGroup) => {
    dispatch(groupsSagaActions.fetchGroupDetails({ groupId: group.systemUserGroupId }))
    setShowDetails(true)
  }

  const deleteGroup = (group: StudentGroup) => {
    dispatch(groupsSagaActions.deleteGroup({ groupId: group.systemUserGroupId }))
  }

  const closeCreateGroup = () => {
    setShowCreateGroup(false)
    setGroupForEdit(null)
  }

  return (
    <MesaUI.Container paddingType="md">
      <MesaUI.Card className="groups-card">
        <MesaUI.CardHeader>
          <MesaUI.CardHeaderLeft>
            <h2>Manage Groups</h2>
            <MesaUI.Button buttonType="primary" marginType={{ left: "md" }} onClick={() => setShowCreateGroup(true)}>
              <i className="fas fa-users"/>
              Create A Group
            </MesaUI.Button>
          </MesaUI.CardHeaderLeft>
        </MesaUI.CardHeader>
        <MesaUI.CardContent className={{ 'loading-whirl': isLoading }}>
          <MesaUI.Container backgroundColor={MesaColorEnum.MIDNIGHT} fontColor={MesaColorEnum.WHITE} paddingType="md">
            <h3>Your Saved Groups</h3>
          </MesaUI.Container>
          {(Array.isArray(studentGroups) && studentGroups.length > 0) ? (
            studentGroups.map((group, i) => (
              <GroupsSavedGroup 
                key={`groups-card-saved-group-${i}-${group.systemUserGroupId}`} 
                group={group} 
                viewDetails={() => viewGroupDetails(group)}
                editGroup={() => editGroup(group)}
                viewGroup={() => viewGroup(group)}
                deleteGroup={() => deleteGroup(group)}
              />
            ))
          ) : (
            <MesaUI.Container className="groups-card__placeholder" paddingType="sm">You haven&apos;t created any groups yet.</MesaUI.Container>
          )}
        <MesaUI.Container className="groups-card__disclaimer" paddingType="sm">*Groups created before v1.1.2 have no details</MesaUI.Container>
        </MesaUI.CardContent>
      </MesaUI.Card>
      {showCreateGroup && (
        <CreateGroupModal
          groupForEdit={groupForEdit}
          close={closeCreateGroup}
        />
      )}
      {showDetails && (
        <GroupsDetailModal 
          isLoading={isLoadingGroupDetails} 
          close={() => setShowDetails(false)} 
          details={groupDetails} 
          campuses={campusOptions}
        />
      )}
    </MesaUI.Container>
  )
}

export default Groups
