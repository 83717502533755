import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '../../mesacloud/react-framework'

import { UserRoleEnum } from '../../lib/enums'
import { filterSagaActions, useSelector } from '../../store'

import Header from './header'
import Footer from './Footer'
import Leftnav from './Leftnav'
import Rightbar from './rightbar'

const Shell = () => {

  const { userRole } = useSelector(({ user }) => user)

  const dispatch = useDispatch()

  useEffect(() => {
    if(![UserRoleEnum.STUDENT, UserRoleEnum.NA].includes(userRole)){
      dispatch(filterSagaActions.fetchActiveFilterStatus())
    }
  }, [dispatch, userRole])

  return (
    <MesaUI.ShellRoot>
      <Header/>
      <Footer/>
      <Leftnav/>
      <Rightbar/>
    </MesaUI.ShellRoot>
  )
}

export default Shell
