
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from "../../../../mesacloud/react-framework"

import StudentMainGPHeader from './StudentMainGPHeader'
import StudentMainGPProgressAdapted from './StudentMainGPProgressAdapted'
import StudentMainGPSubjects from './StudentMainGPSubjects'

import { StudentMainView } from '../../studentMainView'

import {GradPlanDetails, mockStudentCriteriaAggWithAssessments} from '../../../../store/student/studentDomain'
import { useSelector, studentSagaActions } from '../../../../store'

import { useStudentPageContext } from '../../studentPageContext'

import { adaptStudentGradPlanItems } from './adaptStudentGradPlanItems'

import { ENV } from '../../../../lib/env';

interface StudentMainGPPropTypes {
  selectedGradPlan: GradPlanDetails | null;
  setSelectedGradPlan: (gp: GradPlanDetails | null) => void;
  setView: (view: StudentMainView) => void;
}

const StudentMainGP = ({ 
  selectedGradPlan,
  setSelectedGradPlan
}: StudentMainGPPropTypes) => {

  const prevGp = useRef<GradPlanDetails | null>(null)

  const [selectedSubject, setSelectedSubject] = useState("")

  const { 
    allGradPlans,
    studentGradPlanStatus,
    studentProgressSnapshot,
    isLoadingStudentGradPlanStatus,
    isLoadingStudentProgressSnapshot,
    defaultGradPlanName,
    studentCriteriaAggregates,
    studentShouldDisplayLetterGrades
  } = useSelector(({ student }) => student)

  const { studentId, studentLeftPanelCollapsed } = useStudentPageContext()

  const dispatch = useDispatch()

  useEffect(() => {
    if(!selectedGradPlan && !!allGradPlans?.length){
      if(defaultGradPlanName){
        const newGp = allGradPlans.find(({ name }) => name?.trim().toLowerCase() === defaultGradPlanName.trim().toLowerCase()) || allGradPlans[0]
        setSelectedGradPlan(newGp)
      }
    }
    // eslint-disable-next-line
  }, [selectedGradPlan, allGradPlans, defaultGradPlanName])

  useEffect(() => {
    setSelectedSubject("")
    if(selectedGradPlan){
      if(prevGp.current) {
        const gradPlanId = selectedGradPlan.districtAssignedGradPlanId
        dispatch(studentSagaActions.fetchStudentGradPlanStatus({ studentId, gradPlanId }))
        dispatch(studentSagaActions.fetchStudentProgressSnapshot({ studentId, gradPlanId }))
        dispatch(studentSagaActions.fetchStudentCriteriaAggregates({ studentId, gradPlanId }))
      } else {
        prevGp.current = selectedGradPlan
      }
    } else {
      dispatch(studentSagaActions.fetchDefaultStudentGradPlanStatus({ studentId }))
      dispatch(studentSagaActions.fetchDefaultStudentProgressSnapshot({ studentId }))
      dispatch(studentSagaActions.fetchDefaultStudentCriteriaAggregates({ studentId }))
    }
    // eslint-disable-next-line
  }, [selectedGradPlan, studentId])

  // When in demo add the mock criteria aggregates to always have demo assessment data
  const criteriaAggregates = ENV.mesaDemo ? [...studentCriteriaAggregates, ...mockStudentCriteriaAggWithAssessments] : studentCriteriaAggregates;
  const studentGradPlanItems = (isLoadingStudentGradPlanStatus || isLoadingStudentProgressSnapshot) ? (
    []
  ) : (
    adaptStudentGradPlanItems(studentProgressSnapshot, studentGradPlanStatus, criteriaAggregates)
  )

  return (
    <MesaUI.Container className="student__main__gp">
      <StudentMainGPHeader selectedGradPlan={selectedGradPlan} setSelectedGradPlan={setSelectedGradPlan}/>
      <MesaUI.FlexRow className="student__main__gp__content">
        <MesaUI.FlexCol col={1}>
          <StudentMainGPProgressAdapted setSelectedSubject={setSelectedSubject} gradPlanItems={studentGradPlanItems}/>
        </MesaUI.FlexCol>
        <MesaUI.FlexCol col={studentLeftPanelCollapsed ? 3 : 2}>
          <MesaUI.Container className="student__main__subjects-wrapper" paddingType={{ bottom: "lg" }}>
            <StudentMainGPSubjects shouldDisplayLetterGrades={studentShouldDisplayLetterGrades} selectedSubject={selectedSubject} gradPlanItems={studentGradPlanItems}/>
          </MesaUI.Container>
        </MesaUI.FlexCol>
      </MesaUI.FlexRow>
    </MesaUI.Container>
  )
}

export default StudentMainGP
