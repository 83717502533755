
import React from 'react'
import MesaUI from "../../../../mesacloud/react-framework"

import ScoreTotal from '../../common/ScoreTotal'

import { StudentGradPlanItem } from './adaptStudentGradPlanItems'

interface StudentMainGPProgressPropTypes {
  setSelectedSubject: (subjectName: string) => void;
  gradPlanItems: Array<StudentGradPlanItem>;
}

const StudentMainGPProgress = ({ 
  setSelectedSubject,
  gradPlanItems
}: StudentMainGPProgressPropTypes) => {

  const courseGroupItems = gradPlanItems.filter(gpItem => gpItem.entityType === "Course Group" && gpItem.hasProgress)
  const assessmentItems = gradPlanItems.filter(gpItem => gpItem.entityType === "Assessment")
  
  return (
    <MesaUI.Container className="student__main__progress">
      <MesaUI.Container className="student__main__progress__header">
        <h3>Progress Snapshot</h3>
      </MesaUI.Container>
      <MesaUI.Container className="student__main__progress__content-wrap">
        <MesaUI.Container className="student__main__progress__title">
          <span>Courses</span>
        </MesaUI.Container>
        <MesaUI.Container className="student__main__progress__list">
          {courseGroupItems.map(({ htmlLinkId, subjectName, progress, iconClassName }, i) => (
            <MesaUI.Container key={`student__main__progress__item-${i}`} className="student__main__progress__item">
              <MesaUI.Container>
                {subjectName === "Total" ? (
                  subjectName
                ) : (
                  <a href={`#${htmlLinkId}`} onClick={() => setSelectedSubject(subjectName)}>
                    {subjectName}
                  </a>
                )}
              </MesaUI.Container>
              <MesaUI.Container>
                <ScoreTotal
                  amount={progress.earned}
                  total={progress.total}
                  iconFaClass={iconClassName}
                  displayIcon
                />
              </MesaUI.Container>
            </MesaUI.Container>
          ))}
        </MesaUI.Container>
        {!!assessmentItems.length && (
          <>
            <MesaUI.Container className="student__main__progress__title">
              <span>Assessments</span>
            </MesaUI.Container>
            <MesaUI.Container className="student__main__progress__list">
              {assessmentItems.map(({ htmlLinkId, subjectName, iconClassName }, i) => (
                <MesaUI.Container key={`student__main__progress__item-${i}`} className="student__main__progress__item">
                  <MesaUI.Container>
                    <a href={`#${htmlLinkId}`} onClick={() => setSelectedSubject(subjectName)}>
                      {subjectName}
                    </a>
                  </MesaUI.Container>
                  <MesaUI.Container>
                    <i className={iconClassName} style={{ fontSize: '1.15em', margin: '5px' }}/>
                  </MesaUI.Container>
                </MesaUI.Container>
              ))}
            </MesaUI.Container>
          </>
        )}
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default StudentMainGPProgress
