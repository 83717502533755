
import React from 'react'
import createClassName from 'classnames'
import MesaUI from "../../../mesacloud/react-framework"
import { displayAsDecimal, isNaLike } from '../../../mesacloud/corejs'

interface ScoreTotalPropTypes {
  amount: number | string | null | undefined;
  total: number | string | null | undefined;
  displayIcon?: boolean | null;
  iconFaClass?: string;
  [propName: string]: any;
}

const createDisplayNum = (num: any) => isNaLike(num) ? "-" : displayAsDecimal(num)

const ScoreTotal = ({
  amount,
  total,
  displayIcon,
  iconFaClass,
  ...props
}: ScoreTotalPropTypes) =>  (
  <MesaUI.Container className="score-total" {...props}>
    <span className="score-total__left">{createDisplayNum(amount + "")}</span>
    <span className="score-total__mid">/</span>
    <span className="score-total__right">{createDisplayNum(total + "")}</span>
    {displayIcon && (
      <i className={createClassName(iconFaClass || "far fa-circle", "score-total__icon")}/>
    )}
  </MesaUI.Container>
)

export default ScoreTotal
