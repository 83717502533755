
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import { 
  MaybeHasChildren, 
  HasEditableClassName, 
  ExtendableProps
} from '../propsInterfaces'

interface FlexRowPropTypes extends
  MaybeHasChildren,
  HasEditableClassName,
  ExtendableProps {}

const FlexRow = ({ 
  className,
  overrideDefaultClassName,
  children,
  ...props
}: FlexRowPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: { "flex-row": true }, 
    additionalClassName: className, 
    overrideDefaultClassName, 
  })

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  )
}

export default FlexRow