
// displays a number or number-like string as decimal
// If wrong type given, returns blank string
// nDecimal optional arg forces certain number of decimal places
// Trims whitespace and returns trimmed original value if not number-like
export const displayAsDecimal = (s: string | number, nDecimal?: number): string => {
  if(!(typeof s).match(/string|number/)) return ""
  nDecimal = typeof nDecimal === "number" ? (nDecimal < 0 ? 0 : nDecimal) : undefined

  s = (s + "").trim().replace(',','')

  const fl = parseFloat(s)

  return isNaN(fl) ? (
    s
  ) : (
    nDecimal === undefined ? (
      fl % 1 === 0 ? (
        parseInt(s) + ".0"
      ) : (
        s
      )
    ) : (
      fl.toFixed(nDecimal)
    )
  )
}