import { RefObject, useCallback, useMemo } from 'react'

import { useWindowListener } from "./useWindowListener";

/*

  useClickOutside

  Ex: const Component = () => {
    const clickRef = useRef(null)

    useClickOutside(ref, (e) => { handleClickOutside(e) })

    return <div ref={clickRef}>What hath God wrought?</div>
  }

  Usage:
    useClickOutside(ref: React ref, callback: function)

  Creates a window listener while componet is mounted,
  which calls the callback whenever a click event is outside the ref

*/

export const useClickOutside = (ref: RefObject<HTMLElement>, callback: Function) => {

  const _callback = useMemo(() => callback, [callback])

  const listener = useCallback((e) => {
    if(!ref.current || ref.current?.contains(e.target)){
      return
    } else {
      _callback(e)
    }
  }, [_callback])

  useWindowListener("click", listener);
};
