
// Extendable enum class.  Members should be static readonly instances of the class
export class Enum {

  // { key: T }
  // Some typing is unfortunately lost inevitably here.  Overriding and casting the return value can get it cloesr to the correct type.
  static asObject() {
    return (
      Object.entries(this).filter(([key, value]) => {
        void key
        return value instanceof Enum
      }).reduce((obj, [key, val]) => {
        return { ...obj, [key]: val }
      }, {})
    )
  }

  // Array<T>
  // Some typing is unfortunately lost inevitably here.  Overriding and casting the return value can get it cloesr to the correct type.
  static asArray() { 
    return (
      Object.values(this).filter((value) => {
        return value instanceof Enum
      })
    )
  }
}