
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '../../../mesacloud/react-framework'

import { FilterFields, UserFilter, CreateFilterPayload } from '../../../store/filter/filterDomain'
import { MesaColorEnum } from '../../../mesacloud/corejs'
import { filterSagaActions } from '../../../store'

/* eslint-disable */
interface PanelFilterInputPropTypes {
  fields: FilterFields;
  filterForEdit: UserFilter | null;
  cancelEdit: Function;
}
/* eslint-enable */

const blankEditState: CreateFilterPayload = {
  name: "",
  groupIds: [],
  raceIds: [],
  campusIds: [],
  cohorts: [],
  gender: "",
  systemUserFilterId: "",
}

const createEditStateFromFilter = (filter: UserFilter | null): CreateFilterPayload => {
  return !!filter ? ({
    name: filter?.filterParams?.name || "",
    groupIds: filter?.filterParams?.groupIds.map(String) || [],
    raceIds: filter?.filterParams?.raceIds.map(String) || [],
    campusIds: filter?.filterParams?.campusIds.map(String) || [],
    cohorts: filter?.filterParams?.cohorts || [],
    gender: filter?.filterParams?.gender + "" || "",
    systemUserFilterId: filter?.filterParams?.systemUserFilterId || filter?.filterId || ""
  }) : (
    blankEditState
  )
}

const validateEditState = ({
  name, groupIds, raceIds, campusIds, cohorts, gender
}: CreateFilterPayload): boolean => (
  !!name && (!!groupIds?.length || !!raceIds?.length || !!campusIds?.length || !!cohorts?.length || !!gender)
)

const PanelFilterInput = ({
  fields,
  filterForEdit,
  cancelEdit
}: PanelFilterInputPropTypes) => {

  const [editState, setEditState] = useState<CreateFilterPayload>(blankEditState)
  const [editStateValid, setEditStateValid] = useState(false)
  const dispatch = useDispatch()

  const updateEditState = (update: Partial<CreateFilterPayload>) => setEditState({ ...editState, ...update })

  useEffect(() => {
    if(!!filterForEdit){
      setEditState(createEditStateFromFilter(filterForEdit))
    } else {
      updateEditState({ systemUserFilterId: "" })
    }
    // eslint-disable-next-line
  }, [filterForEdit, setEditState])

  useEffect(() => {
    setEditStateValid(validateEditState(editState))
  }, [setEditStateValid, editState])

  const toggleGroupId = (groupId: string) => {
    const existingIndex = editState.groupIds.findIndex(g => g === groupId)
    const groupIds = editState.groupIds.slice()
    if(existingIndex !== -1){
      groupIds.splice(existingIndex, 1)
    } else {
      groupIds.push(groupId)
    }
    updateEditState({ groupIds })
  }

  const toggleRaceId = (raceId: string) => {
    const existingIndex = editState.raceIds.findIndex(r => r === raceId)
    const raceIds = editState.raceIds.slice()
    if(existingIndex !== -1){
      raceIds.splice(existingIndex, 1)
    } else {
      raceIds.push(raceId)
    }
    updateEditState({ raceIds })
  }

  const toggleCampusId = (campusId: string) => {
    const existingIndex = editState.campusIds.findIndex(c => c === campusId)
    const campusIds = editState.campusIds.slice()
    if(existingIndex !== -1){
      campusIds.splice(existingIndex, 1)
    } else {
      campusIds.push(campusId)
    }
    updateEditState({ campusIds })
  }

  const toggleCohort = (cohort: string) => {
    const existingIndex = editState.cohorts.findIndex(c => c === cohort)
    const cohorts = editState.cohorts.slice()
    if(existingIndex !== -1){
      cohorts.splice(existingIndex, 1)
    } else {
      cohorts.push(cohort)
    }
    updateEditState({ cohorts })
  }

  const toggleGenderId = (genderId: string) => updateEditState({ gender: genderId === editState.gender ? "" : genderId })

  const reset = () => {
    setEditState(blankEditState)
    cancelEdit()
  }

  const submit = () => {
    if(editStateValid){
      dispatch(filterSagaActions.saveFilter(editState))
      reset()
    }
  }

  return (
    <>
      <MesaUI.Container paddingType={{ top: "md", left: "lg", bottom: "0", right: "lg" }}>
        <MesaUI.Container paddingType={{ bottom: "md" }} fontColor={MesaColorEnum.ORANGE}>
          <h2>Add or Edit Filter</h2>
        </MesaUI.Container>
        <MesaUI.Container marginType={{ bottom: "sm" }}>
          <MesaUI.Container marginType={{ bottom: "sm" }} fontColor={MesaColorEnum.ORANGE}>
            <h4>Name of Filter</h4>
          </MesaUI.Container>
          <input 
            value={editState.name} 
            maxLength={30}
            type="text"
            onChange={({ target: { value }}) => {
              if(!!filterForEdit && value !== filterForEdit.name){
                cancelEdit()
              }
              updateEditState({ name: value })
            }}
          />
        </MesaUI.Container>
        <MesaUI.Container marginType={{ bottom: "md" }}>
          <MesaUI.Container marginType={{ bottom: "sm" }} fontColor={MesaColorEnum.ORANGE}>
            <h4>Groups</h4>
          </MesaUI.Container>
          {!!fields?.filters?.length ? (
            <ul className="panel__filter__input-list">
              {fields.filters.map(({ groupId, name }, i) => (
                <li key={`filter-field-group-${i}-${groupId}`} onClick={() => toggleGroupId(groupId)}>
                  <input 
                    type="checkbox" 
                    checked={!!editState.groupIds.find(g => g === groupId)} 
                    onChange={() => {}}
                  />
                  <span>{name}</span>
                </li>
              ))}
            </ul>
          ) : (
            <MesaUI.Container paddingType="sm">No groups created</MesaUI.Container>
          )}
        </MesaUI.Container>
        <MesaUI.Container marginType={{ bottom: "md" }}>
          <MesaUI.Container marginType={{ bottom: "sm" }} fontColor={MesaColorEnum.ORANGE}>
            <h4>Campuses</h4>
          </MesaUI.Container>
          {!!fields?.campuses?.length ? (
            <ul className="panel__filter__input-list">
              {fields.campuses.map(({ externalCampusId, campusName }, i) => (
                <li key={`filter-field-campus-${i}-${externalCampusId}`} onClick={() => toggleCampusId(externalCampusId)}>
                  <input 
                    type="checkbox" 
                    checked={!!editState.campusIds.find(c => c === externalCampusId)} 
                    onChange={() => {}}
                  />
                  <span>{campusName}</span>
                </li>
              ))}
            </ul>
          ) : (
            <MesaUI.Container paddingType="sm">No campuses available</MesaUI.Container>
          )}
        </MesaUI.Container>
        <MesaUI.Container marginType={{ bottom: "md" }}>
          <MesaUI.Container marginType={{ bottom: "sm" }} fontColor={MesaColorEnum.ORANGE}>
            <h4>Races</h4>
          </MesaUI.Container>
          {!!fields?.demographics?.length ? (
            <ul className="panel__filter__input-list">
              {fields.demographics.map(({ id, name }, i) => (
                <li key={`filter-field-race-${i}-${id}`} onClick={() => toggleRaceId(id)}>
                  <input 
                    type="checkbox" 
                    checked={!!editState.raceIds.find(r => r === id)} 
                    onChange={() => {}}
                  />
                  <span>{name}</span>
                </li>
              ))}
            </ul>
          ) : (
            <MesaUI.Container paddingType="sm">No demographics available</MesaUI.Container>
          )}
        </MesaUI.Container>
        <MesaUI.Container marginType={{ bottom: "md" }}>
          <MesaUI.Container marginType={{ bottom: "sm" }} fontColor={MesaColorEnum.ORANGE}>
            <h4>Gender</h4>
          </MesaUI.Container>
          {!!fields?.gender?.length ? (
            <ul className="panel__filter__input-list">
              {fields.gender.map(({ id, name }, i) => (
                <li key={`filter-field-gender-${i}-${id}`} onClick={() => toggleGenderId(id)}>
                  <input 
                    type="checkbox" 
                    checked={editState.gender === id} 
                    onChange={() => {}}
                  />
                  <span>{name}</span>
                </li>
              ))}
            </ul>
          ) : (
            <MesaUI.Container paddingType="sm">No gender available</MesaUI.Container>
          )}
        </MesaUI.Container>
        <MesaUI.Container marginType={{ bottom: "md" }}>
          <MesaUI.Container marginType={{ bottom: "sm" }} fontColor={MesaColorEnum.ORANGE}>
            <h4>YE9</h4>
          </MesaUI.Container>
          {!!fields?.cohorts?.length ? (
            <ul className="panel__filter__input-list">
              {fields.cohorts.map((cohort, i) => (
                <li key={`filter-field-cohort-${i}-${cohort}`} onClick={() => toggleCohort(cohort)}>
                  <input 
                    type="checkbox" 
                    checked={!!editState.cohorts.find(c => c === cohort)} 
                    onChange={() => {}}
                  />
                  <span>{cohort}</span>
                </li>
              ))}
            </ul>
          ) : (
            <MesaUI.Container paddingType="sm">No YE9s available</MesaUI.Container>
          )}
        </MesaUI.Container>
      </MesaUI.Container>
      <MesaUI.Container className="panel__filter__input-view__submit-area" paddingType="lg">
        <MesaUI.FlexRow style={{ justifyContent: "center" }}>
          <MesaUI.Button buttonType="primary" marginType={{ right: "md" }} disabled={!editStateValid} onClick={submit}>
            {!!filterForEdit ? "Update Filter" : "Create Filter"}
          </MesaUI.Button>
          <MesaUI.Button buttonType="secondary" onClick={reset}>
            Cancel
          </MesaUI.Button>
        </MesaUI.FlexRow>
      </MesaUI.Container>
    </>
  )
}

export default PanelFilterInput
