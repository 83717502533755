
export const parseBool = (val: string | number | boolean): boolean => {
  if(typeof val !== "string") return !!val

  switch(val.toLowerCase().trim().replace(/[^A-Za-z0-9]/g,'')){
    case "true":
    case "y":
    case "yes":
    case "1":
      return true
    case "false":
    case "n":
    case "no":
    case "na":
    case "n/a":
    case "null":
    case "0":
      return false
    default:
      return !!val
  }
}