
import React from 'react'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

import {
  MaybeHasChildren,
  HasEditableClassName
} from '../../propsInterfaces'

interface HeaderPropTypes extends
  MaybeHasChildren,
  HasEditableClassName {
}

const Header = ({
  children,
  className,
  overrideDefaultClassName
}: HeaderPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "header", 
    additionalClassName: className, 
    overrideDefaultClassName
  })

  return (
    <header className={finalClassName}>
      {children}
    </header>
  )
}

export default Header