
import React, { useRef, useEffect } from 'react'

import { useRootPortal } from '../_utils/useRootPortal'
import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import { useWindowListener } from '../../hooks'

import { debounce } from '../../../corejs'

import { 
  MaybeHasChildren, 
  HasEditableClassName, 
  ExtendableProps
} from '../propsInterfaces'

interface MouseTooltipPropTypes extends
  MaybeHasChildren,
  HasEditableClassName,
  ExtendableProps {
  startingPosition?: [number, number];
}

const MouseTooltip = ({ 
  startingPosition,
  className,
  overrideDefaultClassName,
  children,
  ...props
}: MouseTooltipPropTypes) => {

  const tooltipRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if(tooltipRef && tooltipRef.current){
      if(startingPosition && startingPosition.length === 2){
        tooltipRef.current.style.left = startingPosition[0] + 5 + "px"
        tooltipRef.current.style.top = startingPosition[1] + 5 + "px"
      }
    }
  }, [])

  useWindowListener<MouseEvent>('mousemove', debounce(({ clientX, clientY }: MouseEvent) => {
    if(tooltipRef && tooltipRef.current){
      tooltipRef.current.style.left = clientX + 5 + "px"
      tooltipRef.current.style.top = clientY + 5 + "px"
    }
  }))

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "mouse-tooltip", 
    additionalClassName: className, 
    overrideDefaultClassName, 
  })

  return useRootPortal((
    <div ref={tooltipRef} className={finalClassName} {...props}>
      {children}
    </div>
  ), "tooltip-root")
}

export default MouseTooltip
