
import React, { ReactNode } from 'react'

import { useSelector } from '../store'

import { UserRoleEnum } from '../lib/enums'

interface RoleFilterPropTypes {
  roles: Array<UserRoleEnum>;
  exclude?: boolean;
  children: ReactNode;
}

const RoleFilter = ({
  roles,
  exclude,
  children
}: RoleFilterPropTypes) => {

  const userRole = useSelector(({ user: { userRole } }) => userRole)

  return (
    <>{(!!exclude !== !!roles.includes(userRole)) ? children : null}</> // xor, fragment wrap due to ts + jsx issue
  )
}

export default RoleFilter
