
import React, { Fragment } from 'react'
import MesaUI, { useUuid } from '../../../../mesacloud/react-framework'
import { displayAsDecimal } from '../../../../mesacloud/corejs'

import { CTECluster } from '../../../../store/student/studentDomain'

import ScoreTotal from '../../common/ScoreTotal'
import { CourseGroupStatusEnum } from '../../../../lib/enums'
import { MesaColorEnum } from '../../../../mesacloud/corejs'

interface StudentMainCTEClusterPropTypes {
  cluster: CTECluster;
  selected?: boolean;
}

const StudentMainCTECluster = ({ cluster, selected }: StudentMainCTEClusterPropTypes) => {

  const uuid = useUuid()

  const name = cluster?.name || "N/A"
  const earned = cluster?.totalPathwayCredits
  const total = cluster?.clusterAssignedCredits
  const pathways = Array.isArray(cluster?.ctePathways) ? cluster.ctePathways : []

  const iconClass = earned < total ? "fal fa-circle" : "fas fa-check-circle"

  return (
    <MesaUI.Container paddingType="md">
      <MesaUI.CollapsibleListCard
        id={`student-cte-cluster-status-${name}}`}
        header={(
          <>
            <MesaUI.CardHeaderLeft className="student__main__collapsible-card__title"> 
              <MesaUI.Container fontColor={selected ? MesaColorEnum.SEAFOAM : undefined}>
                <h3>{name}</h3>
              </MesaUI.Container>
            </MesaUI.CardHeaderLeft>
            <MesaUI.CardHeaderRight className="student__main__collapsible-card__status">
              <ScoreTotal 
                amount={earned} 
                total={total}
                displayIcon
                iconFaClass={iconClass}
              />
            </MesaUI.CardHeaderRight>
          </>
        )}
      >
        {pathways.map(({ name, progress, courseDetails, status }, i) => {

          const pathwayStatus = CourseGroupStatusEnum.getByKeyname(status)

          const details = Array.isArray(courseDetails) ? [...courseDetails] : []

          details.sort((a, b) => a?.sequenceId > b?.sequenceId ? 1 : -1)

          const pathwayIconClass = pathwayStatus === CourseGroupStatusEnum.COMPLETED ? "fas fa-check-circle" : "fal fa-circle"

          return (
            <Fragment key={`student-cte-status-cluster-${uuid}-pathway-${i}`}>
              <MesaUI.CollapsibleListCardItem>
                <MesaUI.FlexRow style={{ width: "100%" }}>
                  <MesaUI.FlexCol col={4}>
                    <MesaUI.Container paddingType={{ left: "xl" }} style={{ fontWeight: "bold" }}>{name}</MesaUI.Container>
                  </MesaUI.FlexCol>
                  <MesaUI.FlexCol>
                    <MesaUI.Container fontColor={pathwayStatus.color}>{pathwayStatus.displayName}</MesaUI.Container>
                  </MesaUI.FlexCol>
                  <MesaUI.FlexCol>
                    <MesaUI.Container className="student__main__collapsible-card__status">
                      <ScoreTotal
                        amount={progress}
                        total={total}
                        displayIcon
                        iconFaClass={pathwayIconClass}
                      />
                    </MesaUI.Container>
                  </MesaUI.FlexCol>
                </MesaUI.FlexRow>
              </MesaUI.CollapsibleListCardItem>
              {details.map(({ 
                courseTaken, 
                courseNumber,
                gradeEarned, 
                courseName, 
                creditEarned, 
                enrolled
              }, ii) => (
                <MesaUI.CollapsibleListCardHiddenItem 
                  key={`student-grad-plan-status-subject-${uuid}-course-group-${i}-course-${ii}`}
                >
                  <MesaUI.FlexRow style={{ width: "100%", display: "flex", alignItems: "center" }}>
                    <MesaUI.FlexCol col={4}>
                      <MesaUI.Container paddingType={{ left: "4em" }}>
                        {courseNumber || "N/A"} - {courseName || "N/A"}
                      </MesaUI.Container>
                    </MesaUI.FlexCol>
                    <MesaUI.FlexCol>
                      {!enrolled ? courseTaken : (
                        <MesaUI.Container fontColor={CourseGroupStatusEnum.ENROLLED.color}>
                          Enrolled
                        </MesaUI.Container>
                      )}
                    </MesaUI.FlexCol>
                    <MesaUI.FlexCol>
                      {gradeEarned || "(No grade)"}
                    </MesaUI.FlexCol>
                    <MesaUI.FlexCol>
                      {displayAsDecimal(creditEarned, 1)}
                    </MesaUI.FlexCol>
                  </MesaUI.FlexRow>
                </MesaUI.CollapsibleListCardHiddenItem>
              ))}
            </Fragment>
          )
        })}
      </MesaUI.CollapsibleListCard>
    </MesaUI.Container>
  )
}

export default StudentMainCTECluster
