
import React from 'react'
import MesaUI from '../../../../mesacloud/react-framework'

import { StudentCTE } from '../../../../store/student/studentDomain'
import { useSelector } from '../../../../store'

interface StudentMainCTEHeaderPropTypes {
  selectedCTE: StudentCTE | null;
  setSelectedCTE: (cte: StudentCTE | null) => void;
}

const StudentMainCTEHeader = ({ 
  selectedCTE,
  setSelectedCTE
}: StudentMainCTEHeaderPropTypes) => {

  const { 
    isLoadingStudentCTEs,
    studentCTEs
  } = useSelector(({ student }) => student)

  return (
    <MesaUI.Container className="student__main__gp__header">
      <MesaUI.Container className="student__main__gp__header__left">
        <select 
          className="student__main__gp__header__select"
          value={selectedCTE?.cteId}
          onChange={({ target: { value }}) => setSelectedCTE(studentCTEs?.find(({ cteId }) => cteId + "" === value + "") || null)}
        >
          {Array.isArray(studentCTEs) && studentCTEs.map(({ cteId, name }, i) => (
            <option 
              key={`student__main__gp__header__option-${i}-${cteId}`}
              value={cteId}
            >
              {name}
            </option>
          ))}
        </select>
        {isLoadingStudentCTEs && <MesaUI.LoadingRing seafoam/>}
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default StudentMainCTEHeader
