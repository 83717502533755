
import React, { MouseEvent } from 'react'

import { useRootPortal } from '../_utils/useRootPortal'
import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import { 
  MaybeHasChildren, 
  HasEditableClassName, 
  ExtendableProps
} from '../propsInterfaces'

interface ModalPropTypes extends
  MaybeHasChildren,
  HasEditableClassName,
  ExtendableProps {
  withHeader?: boolean | null;
  closeFunc?: (event: MouseEvent) => void;
}

const Modal = ({ 
  withHeader,
  closeFunc,
  className,
  overrideDefaultClassName,
  children,
  ...props
}: ModalPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: { "modal": !withHeader, "modal--with-header": withHeader }, 
    additionalClassName: className, 
    overrideDefaultClassName, 
  })

  return useRootPortal((
    <div className={finalClassName} {...props}>
      <div className="modal__mask" onClick={typeof closeFunc === "function" ? closeFunc : () => {}}/>
      <div className="modal__content">
        {children}
      </div>
    </div>
  ), "modal-root")
}

export default Modal