
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasPaddingOption,
  HasBackgroundColorOption,
  HasBorderColorOption,
  HasFontColorOption,
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps
} from '../propsInterfaces'

interface SearchResultItemPropTypes extends 
  HasPaddingOption, 
  HasEditableClassName,
  HasBackgroundColorOption,
  HasBorderColorOption,
  HasFontColorOption,
  MaybeHasChildren,
  ExtendableProps {
}

const SearchResultItem = ({
  paddingType,
  className,
  overrideDefaultClassName,
  borderColor,
  backgroundColor,
  fontColor,
  children,
  ...props
}: SearchResultItemPropTypes) => {

  const [finalClassName, style] = useConfigurableStyle({
    defaultClassName: "search__result-list__item",
    additionalClassName: className,
    overrideDefaultClassName,
    borderColor,
    backgroundColor,
    fontColor,
    styleProp: props.style
  })

  return (
    <li className={finalClassName} {...props} style={style}>
      {children}
    </li>
  )
}

export default SearchResultItem