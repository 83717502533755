
const naMatcher = /^(none|nan?|null|n\/a|unavailable|undefined)$/i

export const isNaLike = (val: any): boolean => {

  if(typeof val === "string"){
    return !(val.trim()) || !!(val.trim().match(naMatcher))
  }

  if((typeof val).match(/number|boolean/)){
    return false
  }

  return !val
}