
import React from 'react'
import MesaUI from '../../../mesacloud/react-framework'

import StudentLeftPanelProfile from './StudentLeftPanelProfile'
import StudentLeftPanelSchedule from './StudentLeftPanelSchedule'

interface StudentLeftPanelPropTypes {
  isCollapsed: boolean | null | undefined;
  toggleIsCollapsed: (val?: boolean) => void;
}

const StudentLeftPanel = ({ isCollapsed, toggleIsCollapsed }: StudentLeftPanelPropTypes) => (
  <MesaUI.Container className={{ "student__left-panel": !isCollapsed, "student__left-panel--collapsed": isCollapsed }}>
    <StudentLeftPanelProfile isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed}/>
    <StudentLeftPanelSchedule/>
  </MesaUI.Container>
)

export default StudentLeftPanel
