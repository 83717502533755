
import { Enum } from '../enum/Enum'

export interface MesaColor {
  keyClassName: string;
  rgba: string;
  hex: string;
  backgroundClassName: string;
  borderClassName: string;
  fontClassName: string;
}

export class MesaColorEnum extends Enum implements MesaColor {

  protected constructor(public keyClassName: string, public rgba: string, public hex: string){ super() }

  get backgroundClassName(){
    return `background-color--${this.keyClassName}`
  }
  get borderClassName(){
    return `border-color--${this.keyClassName}`
  }
  get fontClassName(){
    return `font-color--${this.keyClassName}`
  }

  static readonly WHITE     = new MesaColorEnum("white", "rgba(255,255,255, 1)", "#FFFFFF")
  static readonly SNOW      = new MesaColorEnum("snow", "rgba(246,247,249, 1)", "#F6F7F9")
  static readonly SILVER    = new MesaColorEnum("silver", "rgba(211,216,221, 1)", "#D3D8DD")
  static readonly GRAY      = new MesaColorEnum("gray", "rgba(46,62,79, 1)", "#9AA4AF")
  static readonly NAVY      = new MesaColorEnum("navy", "rgba(46,62,79, 1)", "#2E3E4F")
  static readonly ORANGE    = new MesaColorEnum("orange", "rgba(218,91,65, 1)", " #DA5B41")
  static readonly MIDNIGHT  = new MesaColorEnum("midnight", "rgba(19,36,52, 1)", "#132434")
  static readonly GOLD      = new MesaColorEnum("gold", "rgba(232,159,116, 1)", "#E89f74")
  static readonly BRICK     = new MesaColorEnum("brick", "rgba(199, 83, 60, 1)", "#C7533C")
  static readonly RED       = new MesaColorEnum("red", "rgba(185,43,44, 1)", "#B92B2C")
  static readonly SEAFOAM   = new MesaColorEnum("seafoam", "rgba(0,169,157, 1)", "#00A99D")
  static readonly GREEN     = new MesaColorEnum("green", "rgba(92,184,92, 1)", "#5CB85C")
  static readonly EMERALD   = new MesaColorEnum("emerald", "rgba(68,157,68, 1)", "#5CB85C")
  static readonly BLUE      = new MesaColorEnum("blue", "rgba(93,156,236, 1)", "#5D9CEC")
  static readonly APPLE     = new MesaColorEnum("apple", "rgba(169, 40, 40, 1)", "#A92828")
  static readonly DARK_BLUE = new MesaColorEnum("dark-blue", "rgba(95, 106, 190, 1)", "#5F6ABE")

  static asObject(){
    return super.asObject() as { [keyname: string]: MesaColor }
  }

  static asArray(){
    return super.asArray() as Array<MesaColor>
  }
}