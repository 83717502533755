
import React from 'react'
import { useDispatch } from 'react-redux'
import MesaUI, { useDocumentTitle } from "../../../mesacloud/react-framework"

import { useSelector, viewActions, notesSagaActions } from '../../../store'
import { PanelViewEnum, StudentGradPlanStatusEnum } from '../../../lib/enums'

import StudentGPA from './StudentGPA'

import { ENV } from "../../../lib/env";
import { demoCohort } from "../../../lib/demo";

const TRANSCRIPT_SUM_FEATURE_ENABLED = true

interface StudentLeftPanelProfilePropTypes {
  isCollapsed: boolean | null | undefined;
  toggleIsCollapsed: (val?: boolean) => void;
}

const formatCreditEarned = (t: number): string => {
  if(t === -1 || typeof t !== "number") return "N/A"

  // always display 1 decimal place, unless more than 1 decimal in float already, then use 2 decimal places
  return (parseFloat(t.toFixed(1)) === t) ? t.toFixed(1) : t.toFixed(2) 
}

const StudentLeftPanelProfile = ({
  isCollapsed,
  toggleIsCollapsed
}: StudentLeftPanelProfilePropTypes) => {

  const {
    isLoadingStudentInfo,
    studentInfo: {
      cohort,
      dateOfBirth,
      externalStudentId,
      // race,
      riskAssessmentStatus,
      school,
      // studentId,
      studentName,
      systemUserUuid,
      transcriptCreditSum,
      districtHasGpa,
      studentUnweightedGpa,
      gpaRangeSet
    }
  } = useSelector(({ student }) => student)

  const {
    authRoleEnum
  } = useSelector(({user}) => user.whoami)
  const isNotStudent = authRoleEnum !== 'STUDENT'

  useDocumentTitle(`Mesa OnTime: Student - ${externalStudentId}`)

  const dispatch = useDispatch()

  const openNotes = () => {
    if(isLoadingStudentInfo) return
    dispatch(notesSagaActions.fetchUserNotes({ userUuid: systemUserUuid, studentName }))
    dispatch(viewActions.setPanelView({ view: PanelViewEnum.NOTES }))
    dispatch(viewActions.openPanel())
  }

  const gradPlanStatus = StudentGradPlanStatusEnum.getByKeyname(riskAssessmentStatus)

  return (
    <MesaUI.Container id="student-page-student-info" className="student__left-panel__student">
      <MesaUI.Container className="student__left-panel__student__header">
        <h3>{studentName}</h3> {isLoadingStudentInfo && <MesaUI.LoadingRing seafoam/>}
        <MesaUI.Container className="student__left-panel__student__header__toggle no-mobile" onClick={toggleIsCollapsed}>
          <i className={isCollapsed ? "fas fa-chevron-double-right" : "fas fa-chevron-double-left"}/>
        </MesaUI.Container>
      </MesaUI.Container>
      <MesaUI.Container className="student__left-panel__student__info">
        <MesaUI.Container className="student__left-panel__student__info__item">
          <div>Student ID</div>
          <div>{externalStudentId}</div>
        </MesaUI.Container>
        <MesaUI.Container className="student__left-panel__student__info__item">
          <div>School</div>
          <div>{school}</div>
        </MesaUI.Container>
        <MesaUI.Container className="student__left-panel__student__info__item">
          <div>YE9</div>
          <div>{ENV.mesaDemo ? demoCohort(cohort) : cohort}</div>
        </MesaUI.Container>
        <MesaUI.Container className="student__left-panel__student__info__item">
          <div>Birthdate</div>
          <div>{dateOfBirth}</div>
        </MesaUI.Container>
        {TRANSCRIPT_SUM_FEATURE_ENABLED && (
          <MesaUI.Container className="student__left-panel__student__info__item">
            <div>Total Credits Earned</div>
            <div>{formatCreditEarned(transcriptCreditSum)}</div>
          </MesaUI.Container>
        )}
        { isNotStudent &&
          <MesaUI.Container className="student__left-panel__student__info__item">
            <div>Status</div>
            <div style={{ color: gradPlanStatus.color.hex }}>{gradPlanStatus.displayName}</div>
          </MesaUI.Container>
        }
        {districtHasGpa && (
          <MesaUI.Container id="student-profile-gpa" className="student__left-panel__student__info__item">
            <div>GPA (Unweighted)</div>
            <StudentGPA value={studentUnweightedGpa} rangeSet={gpaRangeSet}/>
          </MesaUI.Container>
        )}
        { isNotStudent &&
          <MesaUI.Container className="student__left-panel__student__info__item--notes">
            <div>Notes</div>
            <div onClick={openNotes}>View Notes</div>
          </MesaUI.Container>
        }  
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default StudentLeftPanelProfile
