import { PayloadAction } from '@reduxjs/toolkit'
import { takeEvery, takeLeading, takeLatest, call, put, all } from 'redux-saga/effects'
import { parseBool } from '../../mesacloud/corejs'

import { OnTimeAPI } from '../../lib/api'

import { 
  GroupIDPayload,
  AddOrRemoveGroupStudentPayload,
  CreateGroupPayload,
  GetStudentsByGroupIdPayload,
  GroupList,
  RawStudentGroup,
  mockUserCampuses,
  emptyGroupList,
  emptyStudentGroup,
  mockGroupList,
  mockStudentGroups,
  CampusOption,
  StudentGroup,
  DEFAULT_GROUP_LIST_PAGE_SIZE
} from './groupsDomain'
import * as groupsSagaActions from './groupsSagaActions'
import { errorSlice } from '../error/errorSlice'
import * as filterSagaActions from '../filter/filterSagaActions'
import { groupsSlice } from './groupsSlice'

const tryToParseDetails = (details: any) => {
  try {
    return JSON.parse(details)
  } catch(e){
    return null
  }
}

const fixRawGroup = (rawGroup: RawStudentGroup): StudentGroup => rawGroup ? ({
  ...rawGroup,
  singleStudentsAdded: parseBool(rawGroup.singleStudentsAdded),
  hasDetails: parseBool(rawGroup.hasDetails),
  autogen: parseBool(rawGroup.autogen),
  groupDetails: tryToParseDetails(rawGroup.groupDetails),
}) : emptyStudentGroup

function* fetchStudentGroupsSaga(){
  try {
    yield put(groupsSlice.actions.studentGroupsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{groups: Array<RawStudentGroup> }>('/api/allgroups', {}, { groups: mockStudentGroups })
    })
    const groups: Array<StudentGroup> = result?.data?.groups.map(fixRawGroup) || []
    yield put(groupsSlice.actions.getStudentGroups({ groups }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching groups: ${e}`}))
    yield put(groupsSlice.actions.getStudentGroups({ groups: [] }))
  }
}

function* fetchGroupDetailsSaga({ payload: { groupId } }: PayloadAction<GroupIDPayload>){
  try {
    yield put(groupsSlice.actions.groupDetailsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ groups: Array<RawStudentGroup>}>('/api/group/details', { params: { groupId }}, { groups: mockStudentGroups })
    })
    const groups: Array<StudentGroup> = result?.data?.groups.map(fixRawGroup) || []
    if(groups.length > 0){
      yield put(groupsSlice.actions.getGroupDetails({ newGroup: groups[0] }))
    }
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching groups details: ${e}`}))
    yield put(groupsSlice.actions.getGroupDetails({ newGroup: emptyStudentGroup }))
  }
}

function* fetchCampusOptionsSaga(){
  try {
    yield put(groupsSlice.actions.campusOptionsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ userCampuses: Array<CampusOption> }>('/api/usercampuses', {}, { userCampuses: mockUserCampuses })
    })
    const campuses = result?.data?.userCampuses || []
    yield put(groupsSlice.actions.getCampusOptions({ campuses }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching user campuses: ${e}`}))
    yield put(groupsSlice.actions.getCampusOptions({ campuses: [] }))
  }
}

function* fetchGroupListSaga({ 
  payload: { 
    groupId,
    groupName,
    page,
    isSingleAddOnly
  } 
}: PayloadAction<GetStudentsByGroupIdPayload>){
  try {
    yield put(groupsSlice.actions.groupListRequested({ groupId, groupName }))
    const params = { groupId, page }
    const result = yield call(() => {
      return isSingleAddOnly ? (
        OnTimeAPI.get<GroupList>('/api/group/singleadds', { params: { ...params, size: DEFAULT_GROUP_LIST_PAGE_SIZE } }, mockGroupList)
      ) : (
        OnTimeAPI.get<GroupList>('/api/selectgroup', { params: { ...params, size: DEFAULT_GROUP_LIST_PAGE_SIZE} }, mockGroupList)
      ) 
    })
    const groupListName = result?.data?.groupName || ""
    const students = result?.data?.students || []
    const numberOfPages = result?.data?.numberOfPages || 1
    yield put(groupsSlice.actions.getGroupList({ groupList: { groupName: groupListName, students, numberOfPages } }))
    if(page === 0){
      yield put(groupsSagaActions.fetchStudentGroups()) // due to potential ID change
    }
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching group list: ${e}`}))
    yield put(groupsSlice.actions.getGroupList({ groupList: emptyGroupList }))
  }
}

function* addStudentToGroupSaga({ payload }: PayloadAction<AddOrRemoveGroupStudentPayload>){
  try {
    yield put(groupsSlice.actions.addStudentToGroupRequested())
    yield call(() => OnTimeAPI.post('/api/addstudenttogroup', payload, {}, {}))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error adding student to group: ${e}`}))
  } finally {
    yield put(groupsSlice.actions.addStudentToGroupComplete())
  }
}

function* removeStudentFromGroupSaga({ payload }: PayloadAction<AddOrRemoveGroupStudentPayload>){
  try {
    yield put(groupsSlice.actions.removeStudentFromGroupRequested())
    yield call(() => OnTimeAPI.put('/api/removefromgroup', payload, {}, {}))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error removing student from group: ${e}`}))
  } finally {
    yield put(groupsSlice.actions.removeStudentFromGroupComplete())
  }
}

function* deleteGroupSaga({ payload }: PayloadAction<GroupIDPayload>){
  try {
    yield put(groupsSlice.actions.deleteGroupRequested())
    yield call(() => OnTimeAPI.delete('/api/removeGroup', { params: payload }, {}))
    yield put(groupsSagaActions.fetchStudentGroups())
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error deleting group: ${e}`}))
  } finally {
    yield put(groupsSlice.actions.deleteGroupComplete())
  }
}

function* createGroupSaga({ payload }: PayloadAction<CreateGroupPayload>){
  try {
    yield put(groupsSlice.actions.createGroupRequested())
    yield call(() => OnTimeAPI.post('/api/createGroup', payload, {}, {}))
    yield put(groupsSagaActions.fetchStudentGroups())
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error creating group: ${e}`}))
  } finally {
    yield put(groupsSlice.actions.createGroupComplete())
    yield put(filterSagaActions.fetchFilterFields()) // New groups need to be available in the filter fields
  }
}

export function* rootGroupsSaga(){
  yield all([
    takeLatest(groupsSagaActions.fetchStudentGroups.type, fetchStudentGroupsSaga),
    takeLatest(groupsSagaActions.fetchGroupDetails.type, fetchGroupDetailsSaga),
    takeLeading(groupsSagaActions.fetchCampusOptions.type, fetchCampusOptionsSaga),
    takeLatest(groupsSagaActions.fetchGroupList.type, fetchGroupListSaga),
    takeEvery(groupsSagaActions.addStudentToGroup.type, addStudentToGroupSaga),
    takeEvery(groupsSagaActions.removeStudentFromGroup.type, removeStudentFromGroupSaga),
    takeEvery(groupsSagaActions.deleteGroup.type, deleteGroupSaga),
    takeLeading(groupsSagaActions.createGroup.type, createGroupSaga)
  ])
}
