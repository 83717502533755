
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI, { useDocumentTitle } from "../../mesacloud/react-framework"

import { AvailableFlagSortKey, FlagType, FlagDTO } from '../../store/flags/flagsDomain'
import { useSelector, flagsSagaActions, loadTriggersActions } from '../../store'

import { FlagStatusEnum, UserRoleEnum } from '../../lib/enums'

import FlagsHeader from './FlagsHeader'
import FlagsTableHeader from './FlagsTableHeader'
import FlagsTableFlag from './FlagsTableFlag'

import { useRedirectRoles, useCloseNotesOnFirstLoad } from '../../hooks'

const FLAGS_PAGE_SIZE = 25

const Flags = () => {

  useDocumentTitle("Mesa OnTime: Flags")
  useRedirectRoles([UserRoleEnum.STUDENT])
  useCloseNotesOnFirstLoad()

  const [flagsPage, setFlagsPage] = useState(0)
  const [selectedFlags, setSelectedFlags] = useState<Array<FlagDTO>>([])
  const [flagTypeViewed, setFlagTypeViewed] = useState<FlagType | null>(null)
  const [flagSortKey, setFlagSortKey] = useState<AvailableFlagSortKey>("studentName")
  const [flagSortAsc, setFlagSortAsc] = useState(true)
  const [flagStatusViewed, setFlagStatusViewed] = useState<FlagStatusEnum>(FlagStatusEnum.UNRESOLVED)

  const { flagsPageShouldLoadEventual, flagsPageShouldLoadImmediate } = useSelector(({ loadTriggers }) => loadTriggers)

  const {
    flagTypes,
    flags,
    isLoadingFlagTypes,
    isLoadingFlags,
    isUpdatingFlagStatus,
    totalFlagPages,
  } = useSelector(({ flags }) => flags)

  const isLoading = isLoadingFlags || isUpdatingFlagStatus || isLoadingFlagTypes

  const dispatch = useDispatch()

  const fetchFlagsPayload = {  
    page: flagsPage,
    flagStatus: flagStatusViewed.keyname,
    flagTypeId: flagTypeViewed === null ? "0" : flagTypeViewed.flagTypeId,
    sortKey: flagSortKey,
    sortAsc: flagSortAsc,
    size: FLAGS_PAGE_SIZE,
  }

  const fetchFlags = (payload = fetchFlagsPayload) => {
    setSelectedFlags([])
    dispatch(flagsSagaActions.fetchFlags(payload))
    dispatch(loadTriggersActions.flagsPageLoaded())
  }

  const loadPage = () => {
    if(!flagTypes?.length && !isLoadingFlagTypes){
      dispatch(flagsSagaActions.fetchFlagTypes())
    }
    fetchFlags()
  }

  const handleSelectSortKey = (sortKey: AvailableFlagSortKey) => {
    let sortAsc = !flagSortAsc
    if(sortKey === flagSortKey){
      setFlagSortAsc(sortAsc)
    } else {
      sortAsc = true
      setFlagSortKey(sortKey)
      setFlagSortAsc(sortAsc)
    }
    fetchFlags({ ...fetchFlagsPayload, sortKey, sortAsc  })
  }

  const handleSelectFlagType = (flagType: FlagType | null) => {
    if(flagType === flagTypeViewed){
      setFlagTypeViewed(null)
      flagType = null
    } else {
      setFlagTypeViewed(flagType)
    }
    fetchFlags({ ...fetchFlagsPayload, flagTypeId: flagType?.flagTypeId || "0" })
  }

  const handleSelectFlagStatus = (flagStatus: FlagStatusEnum) => {
    if(flagStatus === flagStatusViewed && flagStatus !== FlagStatusEnum.UNRESOLVED){
      setFlagStatusViewed(FlagStatusEnum.UNRESOLVED)
      fetchFlags({ ...fetchFlagsPayload, flagStatus: FlagStatusEnum.UNRESOLVED.keyname })
    } else {
      setFlagStatusViewed(flagStatus)
      fetchFlags({ ...fetchFlagsPayload, flagStatus: flagStatus.keyname })
    }
  }

  const handleSelectFlag = (flag: FlagDTO) => {
    const existingIndex = selectedFlags.findIndex(_flag => _flag.studentFlagUUID === flag.studentFlagUUID)
    const copy = selectedFlags.slice()
    if(existingIndex !== -1){
      copy.splice(existingIndex, 1)
    } else {
      copy.push(flag)
    }
    setSelectedFlags(copy)
  }

  const handlePageChange = ({ selected }: { selected: number }) => {
    setFlagsPage(selected)
    fetchFlags({ ...fetchFlagsPayload, page: selected })
  }

  const allFlagsSelected = !!flags.length && selectedFlags.length === flags.length

  const handleSelectAllFlags = () => {
    if(allFlagsSelected){
      setSelectedFlags([])
    } else {
      setSelectedFlags(flags?.slice() || [])
    }
  }

  // Initial load only
  useEffect(() => {
    if(flagsPageShouldLoadEventual || flagsPageShouldLoadImmediate){
      loadPage()
    }
    // eslint-disable-next-line
  }, [])

  // Saga in loadTriggers has side effect to trigger immediate refresh when Filter changes
  useEffect(() => {
    if(flagsPageShouldLoadImmediate){
      loadPage()
    }
    // eslint-disable-next-line
  }, [flagsPageShouldLoadImmediate, dispatch])

  useEffect(() => {
    if(flagTypeViewed === null && flagTypes.length > 0){
      setFlagTypeViewed(flagTypes[0])
    }
  }, [flagTypes, flagTypeViewed, setFlagTypeViewed])

  return (
    <MesaUI.Container paddingType="md">
      <MesaUI.Card className={{ 'flags-card': true, 'loading-whirl': isLoading }}>
        <FlagsHeader
          selectFlagStatus={handleSelectFlagStatus}
          selectFlagType={handleSelectFlagType}
          selectedFlagType={flagTypeViewed}
          flagsStatus={flagStatusViewed}
          selectedFlags={selectedFlags}
          flagTypes={flagTypes}
        />
        <MesaUI.CardContent>
          <MesaUI.Table className="flags-card__table">
            <FlagsTableHeader
              allFlagsSelected={allFlagsSelected}
              selectAllFlags={handleSelectAllFlags}
              selectSortKey={handleSelectSortKey}
              currentAsc={flagSortAsc}
              currentSortKey={flagSortKey}
            />
            <span className="flags-card__flags">
              {Array.isArray(flags) && flags.map((flag, i) => (
                <FlagsTableFlag
                  key={`flags-card__table__flag-${i}-${flag.studentFlagUUID}`}
                  flag={flag}
                  selected={!!selectedFlags.find(selectedFlag => selectedFlag.studentFlagUUID === flag.studentFlagUUID)}
                  onSelect={() => handleSelectFlag(flag)}
                />
              ))}
            </span>
          </MesaUI.Table>
          <MesaUI.Container className="flags-card__footer">
            <MesaUI.Pagination page={flagsPage} totalPages={totalFlagPages || 0} withPageJump={totalFlagPages > 1} handlePageChange={handlePageChange}/>
          </MesaUI.Container>
        </MesaUI.CardContent>
      </MesaUI.Card>
    </MesaUI.Container>
  )
}

export default Flags
