
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps,
  HasPaddingOption,
  HasConfigurableColors,
} from '../propsInterfaces'

interface TableRowPropTypes extends
  HasEditableClassName,
  MaybeHasChildren, 
  HasPaddingOption,
  HasConfigurableColors,
  ExtendableProps {
  interactive?: boolean | null;
}

const TableRow = ({
  children,
  className,
  overrideDefaultClassName,
  interactive,
  paddingType,
  backgroundColor,
  borderColor,
  fontColor,
  ...props
}: TableRowPropTypes) => {

  const [finalClassName, style] = useConfigurableStyle({
    defaultClassName: { "table__cell": !interactive, "table__cell--interactive": interactive },
    additionalClassName: className, 
    overrideDefaultClassName,
    paddingType,
    backgroundColor,
    borderColor,
    fontColor,
    styleProp: props.style
  })

  return (
    <div className={finalClassName} {...props} style={style}>
      {children}
    </div>
  )
}

export default TableRow