import React from 'react'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

import {
  MaybeHasChildren,
  HasEditableClassName,
  ExtendableProps
} from '../../propsInterfaces'

interface FooterPropTypes extends
  MaybeHasChildren,
  HasEditableClassName,
  ExtendableProps {
  defaultConfig?: {
    appName: string;
    appVersion: string;
  };
}

const Footer = ({
  defaultConfig,
  children,
  className,
  overrideDefaultClassName,
  ...props
}: FooterPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: {
      "footer": true,
    }, 
    additionalClassName: className, 
    overrideDefaultClassName
  })

  return (
    <div className={finalClassName} {...props}>
      {defaultConfig ? (
        `${defaultConfig.appName || ""} ${defaultConfig.appVersion || ""} 
        © ${new Date().getFullYear()} Mesa Cloud, Inc. All Rights Reserved`
      ) : (
        children
      )}
    </div>
  )
}

export default Footer
