import isEqual from 'lodash.isequal'
import { alphaIndexMap } from '../../../mesacloud/corejs'

import { StudentGroup, CreateGroupPayload, CreateGroupSpecialSelector } from "../../../store/groups/groupsDomain"

export const emptyInputState: CreateGroupPayload = {
  name: "",
  groupId: "",
  resetSingleAddedStudents: false,
  specialSelectors: [],
  studentIds: [],
}

export const defaultSelector: CreateGroupSpecialSelector = {
  ye9Filters: [],
  campusIds: [],
  lastNameFilter: ['A', 'Z']
}

export const getInputStateFromGroup = (group: StudentGroup | null): CreateGroupPayload => {
  if(!group || !group.hasDetails || !group.groupDetails || typeof group?.groupDetails !== "object") return emptyInputState

  const result = { ...emptyInputState }

  const { name, groupId, specialSelectors, studentIds } = group.groupDetails

  if(name && typeof name === "string"){
    result.name = name
  }

  result.groupId = group.systemUserGroupId || groupId

  if(Array.isArray(specialSelectors)){
    const selectors: Array<CreateGroupSpecialSelector> = []
    specialSelectors.forEach((specialSelector) => {
      if(!specialSelector || typeof specialSelector !== "object"){
        return
      }

      if(!Array.isArray(specialSelector.campusIds) || !Array.isArray(specialSelector.lastNameFilter) || !Array.isArray(specialSelector.ye9Filters)){
        return
      }

      if(specialSelector.lastNameFilter.length !== 2){
        return
      }

      selectors.push(specialSelector as { ye9Filters: Array<string>; campusIds: Array<string>; lastNameFilter: [string, string] })
    }, true)
    result.specialSelectors = selectors
  }

  if(Array.isArray(studentIds)){
    const _studentIds: Array<string> = []
    studentIds.forEach(studentId => {
      if(typeof studentId === "string"){
        _studentIds.push(studentId)
      }
    })
    result.studentIds = _studentIds
  }

  return result
}

const groupHasBeenEdited = (inputState: CreateGroupPayload, groupForEdit: StudentGroup): boolean => {
  if(inputState?.resetSingleAddedStudents) return true

  const relevantOriginalDetails: Partial<CreateGroupPayload> = {
    name: groupForEdit?.groupDetails?.name || "",
    specialSelectors: groupForEdit?.groupDetails?.specialSelectors || [],
    studentIds: groupForEdit?.groupDetails?.studentIds || []
  }

  const relevantInputDetails: Partial<CreateGroupPayload> = {
    name: inputState?.name || "",
    specialSelectors: inputState?.specialSelectors || [],
    studentIds: inputState?.studentIds || []
  }

  return !isEqual(relevantInputDetails, relevantOriginalDetails)
}

const convertLastNameValueToInt = (lastNameVal: string, filler = "A"): number => {
  const filledVal = (lastNameVal.slice(0,3) + filler + filler + filler).slice(0,3)
  return filledVal.split('').reduce((total, char, i) => total + alphaIndexMap[char] * Math.pow(26, filledVal.length - 1 - i), 0)
}

export const validateLastNameFilter = (val: [string, string]): boolean => {
  if(val?.length !== 2) return false
  return convertLastNameValueToInt(val[0]) <= convertLastNameValueToInt(val[1])
}

export const validateInputState = (inputState: CreateGroupPayload, groupForEdit: StudentGroup | null): [boolean, string] => {

  if(!inputState.name){
    return [false, "Please enter a group name to continue"]
  }

  if(groupForEdit !== null){
    if(!groupHasBeenEdited(inputState, groupForEdit)){
      return [false, "No changes made"]
    }
  } else {
    if(!inputState?.specialSelectors?.length && !inputState?.studentIds?.length){
      return [false, "Add students to continue"]
    }
  }

  let hasErr = false
  inputState?.specialSelectors?.forEach(selector => {
    if(!validateLastNameFilter(selector?.lastNameFilter)){
      hasErr = true
    }
  })
  if(hasErr){
    return [false, "Fix last name errors in your above selections: The 2nd set of letters must come after the 1st alphabetically."]
  }

  return [true, ""]
}
