import React from 'react'
import createClassName from 'classnames'
import { NavLink } from 'react-router-dom'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

import {
  MaybeHasChildren,
  HasEditableClassName,
  ExtendableProps
} from '../../propsInterfaces'

interface LeftnavItemPropTypes extends
  HasEditableClassName,
  ExtendableProps {
  active?: boolean | null;
  isLinkTo?: string;
  autoActivateWithLink?: boolean | null;
  iconFaClass?: string;
  labelText?: string;
}

interface InnerNavItemPropTypes extends MaybeHasChildren, ExtendableProps {}

const LeftnavItem = ({
  active,
  className,
  overrideDefaultClassName,
  iconFaClass,
  isLinkTo,
  labelText,
  autoActivateWithLink,
  ...props
}: LeftnavItemPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: {
      "leftnav__item": !active,
      "leftnav__item--active": active,
    }, 
    additionalClassName: className, 
    overrideDefaultClassName
  })

  const iconClassName = createClassName({
    "leftnav__item__icon": true,
    [iconFaClass + ""]: typeof iconFaClass === "string"
  })

  const linkClasses: string[] = [finalClassName];
  if (autoActivateWithLink) {
    linkClasses.push("leftnav__item--active");
  }

  const InnerNavItem = typeof isLinkTo === "string" ? (
    ({ children, ...props }: InnerNavItemPropTypes) => (
      <NavLink
        to={isLinkTo}
        className={linkClasses.join(" ")}
        {...props}
      >
        {children}
      </NavLink>
    )
  ) : (
    ({ children, ...props }: InnerNavItemPropTypes) => (
      <div className={finalClassName} {...props}>{children}</div>
    )
  )

  return (
    <InnerNavItem className={finalClassName} {...props}>
      <i className={iconClassName}/>
      <div className="leftnav__item__label">
        {labelText}
      </div>
    </InnerNavItem>
  )
}

LeftnavItem.defaultProps ={
  iconFaClass: "fas fa-circle"
}

export default LeftnavItem
