
import React from 'react'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

import {
  MaybeHasChildren,
  HasEditableClassName
} from '../../propsInterfaces'

interface HeaderLeftPropTypes extends
  MaybeHasChildren,
  HasEditableClassName {

}

const HeaderLeft = ({
  children,
  className,
  overrideDefaultClassName,
}: HeaderLeftPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "header__left", 
    additionalClassName: className, 
    overrideDefaultClassName
  })

  return (
    <div  className={finalClassName}>
      {children}
    </div>
  )
}

export default HeaderLeft