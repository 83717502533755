
import { Enum } from '../../mesacloud/corejs'

export interface FlagStatus {
  keyname: string;
  displayName: string;
}

export class FlagStatusEnum extends Enum implements FlagStatus {

  public static ARCHIVED = new FlagStatusEnum("AR", "Archived")
  public static RESOLVED = new FlagStatusEnum("RE", "Resolved")
  public static UNRESOLVED = new FlagStatusEnum("UN", "Unresolved")

  protected constructor(public keyname: string, public displayName: string){ super() }

  public static getByKeyname(keyname: string){
    return this.asArray().find(({ keyname: _keyname }) => _keyname === keyname) || this.UNRESOLVED
  }

  public static getByDisplayName(displayName: string): FlagStatusEnum{
    return this.asArray().find(({ displayName: _displayName }) => _displayName === displayName) || this.UNRESOLVED
  }

  public static asArray(){
    return super.asArray() as Array<FlagStatus>
  }

  public static asObject(){
    return super.asObject() as { [keyname: string]: FlagStatus }
  }

}
