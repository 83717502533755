
import React from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '../../../mesacloud/react-framework'

import { viewActions, useSelector, notesSagaActions } from '../../../store'

import PanelNotesNote from './PanelNotesNote'
import PanelNotesInput from './PanelNotesInput'

const PanelNotes = () => {

  const dispatch = useDispatch()

  const { 
    notes,
    currentNotesFlagName,
    currentNotesStudentName,
    currentNotesFlagUUID,
    currentNotesStudentUUID,
    currentNotesView,
    isLoadingFlagNotes,
    isLoadingUserNotes,
    isSavingFlagNote,
    isSavingUserNote
  } = useSelector(({ notes }) => notes)

  const isLoading = isLoadingFlagNotes || isSavingFlagNote || isLoadingUserNotes || isSavingUserNote

  const submitNote = (text: string) => {
    switch(currentNotesView){
      case "flag":
        dispatch(notesSagaActions.saveFlagNote({ 
          recipientUuid: currentNotesStudentUUID,
          flagUuid: currentNotesFlagUUID,
          noteContent: text
        }))
        break
      case "student":
        dispatch(notesSagaActions.saveUserNote({ 
          recipientUuid: currentNotesStudentUUID,
          noteContent: text
        }))
        break
      default:
        break
    }
  }

  return (
    <>
      <MesaUI.PanelHeader>
        <h2>Notes</h2>
        <MesaUI.Button buttonType="close" onClick={() => dispatch(viewActions.closePanel())}/>
      </MesaUI.PanelHeader>
      <MesaUI.PanelContent className={{ "panel-notes": true, "loading-whirl": isLoading }}>
        <span>
          <MesaUI.Container className="panel-notes__info" paddingType="md">
            <div>Student: <span>{currentNotesStudentName}</span></div>
            {currentNotesView === "flag" && (
              <div>Flag: <span>{currentNotesFlagName}</span></div>
            )}
          </MesaUI.Container>
          <MesaUI.Container className="panel-notes__notes">
            {notes.filter(n => n.enabled).map((note, i) => note ? (
              <PanelNotesNote key={`panel-notes-note-${i}-${note.uuid}`} note={note}/>
            ) : null)}
          </MesaUI.Container>
        </span>
        <PanelNotesInput submitNote={submitNote}/>
      </MesaUI.PanelContent>
    </>
  )
}

export default PanelNotes
