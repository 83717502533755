import React from 'react'
import createClassName from 'classnames'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

import {
  ExtendableProps,
  HasEditableClassName
} from '../../propsInterfaces'

interface RightbarItemPropTypes extends
  ExtendableProps,
  HasEditableClassName {
  iconFaClass?: string;
  tooltipText?: string;
  tooltipClassNameOptions?: HasEditableClassName;
  active?: boolean | null;
  selected?: boolean | null;
}

const RightbarItem = ({
  className,
  overrideDefaultClassName,
  iconFaClass,
  tooltipText,
  active,
  selected,
  tooltipClassNameOptions,
  ...props
}: RightbarItemPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: {
      "rightbar__item": !active && !selected,
      "rightbar__item--active": active,
      "rightbar__item--selected": selected
    }, 
    additionalClassName: className, 
    overrideDefaultClassName
  })

  const [tooltipClassName] = useConfigurableStyle({
    defaultClassName: "rightbar__item__tooltip",
    additionalClassName: tooltipClassNameOptions?.className, 
    overrideDefaultClassName: tooltipClassNameOptions?.overrideDefaultClassName
  })

  const hasIconClass = !!(iconFaClass && typeof iconFaClass === "string")

  const iconClassName = createClassName({
    "rightbar__item__icon": true,
    [iconFaClass + ""]: hasIconClass,
    "fa fa-circle": !hasIconClass
  })

  return (
    <div className={finalClassName} {...props}>
      <i className={iconClassName}/>
      {tooltipText && <div className={tooltipClassName}>{tooltipText}</div>}
    </div>
  )
}

export default RightbarItem
