import React from 'react'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

import {
  MaybeHasChildren,
  ExtendableProps,
  HasEditableClassName
} from '../../propsInterfaces'

interface RightbarPropTypes extends
  MaybeHasChildren,
  ExtendableProps,
  HasEditableClassName {
}

const Rightbar = ({
  children,
  className,
  overrideDefaultClassName,
  ...props
}: RightbarPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "rightbar", 
    additionalClassName: className, 
    overrideDefaultClassName
  })

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  )
}

export default Rightbar