
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import joinUrls from 'url-join'
import MesaUI from '../../mesacloud/react-framework'

import { FlagDTO } from '../../store/flags/flagsDomain'
import { flagsSagaActions, notesSagaActions, viewActions } from '../../store'

import { FlagStatusEnum, PanelViewEnum } from '../../lib/enums'

interface FlagsTableFlagPropTypes {
  flag: FlagDTO;
  selected: boolean | null | undefined;
  onSelect: () => any;
}

const FlagsTableFlag = ({
  flag,
  selected,
  onSelect,
}: FlagsTableFlagPropTypes) => {

  const dispatch = useDispatch()

  const updateStatus = (statusKeyname: string) => {
    dispatch(flagsSagaActions.updateFlagsStatus({ studentFlagIds: [flag?.studentFlagId], status: statusKeyname }))
  }

  const openNotes = () => {
    dispatch(notesSagaActions.fetchFlagNotes({ flagName: flag?.flagName, studentName: flag?.studentName, userUuid: flag?.studentSystemUserUUID, flagUuid: flag?.studentFlagUUID }))
    dispatch(viewActions.setPanelView({ view: PanelViewEnum.NOTES }))
    dispatch(viewActions.openPanel())
  }

  const flagStatus = FlagStatusEnum.getByDisplayName(flag?.status)

  const lastModified = new Date(flag?.lastModifiedDate)

  return (
    <MesaUI.TableRow className="flags-card__table__flag">
      <MesaUI.TableCell>
        <input type="checkbox" checked={!!selected} onChange={() => onSelect()}/>
      </MesaUI.TableCell>
      <MesaUI.TableCell>
        <NavLink to={joinUrls('/student', flag?.studentId || "")} target="_blank">
          {flag?.lastName || "-"}, {flag?.firstName || "-"}
        </NavLink>
      </MesaUI.TableCell>
      <MesaUI.TableCell>
        {flag?.districtAssignedStudentId || "N/A"}
      </MesaUI.TableCell>
      <MesaUI.TableCell style={{ flex: 2 }}>
        {flag?.flagName || "N/A"}
      </MesaUI.TableCell>
      <MesaUI.TableCell>
        {flag?.flagType || "N/A"}
      </MesaUI.TableCell>
      <MesaUI.TableCell>
        {lastModified.toLocaleString()}
      </MesaUI.TableCell>
      <MesaUI.TableCell className="flags-card__table__flag__status">
        <select value={flagStatus.keyname} onChange={({ target: { value } }) => updateStatus(value)}>
          {FlagStatusEnum.asArray().map((status,i) => (
            <option value={status.keyname} key={`flag-${flag?.studentFlagUUID}-${flag?.studentFlagId}-status-option-${i}-${status.keyname}`}>
              {status.displayName}
            </option>
          ))}
        </select>
      </MesaUI.TableCell>
      <MesaUI.TableCell className="flags-card__table__flag__notes">
        <i className='fa fa-sticky-note' onClick={openNotes}/> <span>{flag?.noteCnt}</span>
      </MesaUI.TableCell>
    </MesaUI.TableRow>
  )
}

export default FlagsTableFlag
