
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps,
  HasMarginOption,
} from '../propsInterfaces'

interface TablePropTypes extends
  HasEditableClassName,
  MaybeHasChildren, 
  HasMarginOption,
  ExtendableProps {
  rounded?: boolean | null;
}

const Table = ({
  children,
  className,
  overrideDefaultClassName,
  rounded,
  marginType,
  ...props
}: TablePropTypes) => {

  const [finalClassName, style] = useConfigurableStyle({
    defaultClassName: { "table": !rounded, "table--rounded": rounded },
    additionalClassName: className, 
    overrideDefaultClassName,
    marginType,
    styleProp: props.style
  })

  return (
    <div className={finalClassName} {...props} style={style}>
      {children}
    </div>
  )
}

export default Table