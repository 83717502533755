
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps
} from '../propsInterfaces'

interface PanelContentPropTypes extends
  HasEditableClassName,
  MaybeHasChildren, 
  ExtendableProps {
}

const PanelContent = ({
  className,
  overrideDefaultClassName,
  children,
  ...props
}: PanelContentPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "panel__view__content",
    additionalClassName: className, 
    overrideDefaultClassName, 
  })

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  )
}

export default PanelContent