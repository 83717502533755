import { MesaColor, Enum, MesaColorEnum } from "../../mesacloud/corejs";

export type StatusKeyname = "Partial" | "Required" | "Completed" | "Scheduled" | "Scheduled At Risk" | "Future Requirement" | "Not Completed" | "Enrolled"  | "na"

export interface StudentSubjectStatus {
  keyname: string;
  color: MesaColor;
  displayName: string;
}

export class StudentSubjectStatusEnum extends Enum implements StudentSubjectStatus {

  public static PARTIAL = new StudentSubjectStatusEnum("Partial", MesaColorEnum.ORANGE, "Partial")
  public static REQUIRED = new StudentSubjectStatusEnum("Required", MesaColorEnum.RED, "Failed")
  public static COMPLETED = new StudentSubjectStatusEnum("Completed", MesaColorEnum.GREEN, "Completed")
  public static SCHEDULED = new StudentSubjectStatusEnum("Scheduled", MesaColorEnum.DARK_BLUE, "Scheduled")
  public static SCHEDULED_AT_RISK = new StudentSubjectStatusEnum("Scheduled At Risk", MesaColorEnum.DARK_BLUE, "Scheduled At Risk")
  public static ENROLLED = new StudentSubjectStatusEnum("Enrolled", MesaColorEnum.BLUE, "Enrolled")
  public static FUTURE_REQUIREMENT = new StudentSubjectStatusEnum("Future Requirement", MesaColorEnum.MIDNIGHT, "Future Requirement")
  public static NOT_COMPLETED = new StudentSubjectStatusEnum("Not Completed", MesaColorEnum.GRAY, "Not Completed")
  public static NA = new StudentSubjectStatusEnum("na", MesaColorEnum.GRAY, "N/A")
  
  public static getByKeyname(keyname: string): StudentSubjectStatus {
    return this.asArray().find(({ keyname: _keyname }) => _keyname.trim().toLowerCase() === keyname.trim().toLowerCase()) || this.NA
  }

  protected constructor(
    public keyname: string,
    public color: MesaColor,
    public displayName: string
  ) { super() }

  public static asArray(){
    return super.asArray() as Array<StudentSubjectStatus>;
  }

  public static asObject(){
    return super.asObject() as { [key: string]: StudentSubjectStatus }
  }
}
