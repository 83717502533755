
import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../store'
import MesaUI from '../../../mesacloud/react-framework'
import { MesaColorEnum } from '../../../mesacloud/corejs'

import { errorActions } from '../../../store'

const HeaderErrorMessage = () => {

  const { errorMessage, errorIsDisplayed } = useSelector(({ error }) => error)

  const dispatch = useDispatch()

  return (
    <MesaUI.Container 
      className={{ 
        "header__error-message": errorIsDisplayed, 
        "header__error-message--hidden": !errorIsDisplayed 
      }}
      backgroundColor={MesaColorEnum.RED} 
      fontColor={MesaColorEnum.WHITE}
      paddingType="sm"
      onClick={() => dispatch(errorActions.hideError())}
    >
      {errorMessage}
    </MesaUI.Container>
  )
}

export default HeaderErrorMessage
