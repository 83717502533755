import { v4 as uuidv4 } from 'uuid'

export interface NoteDTO {
  id: number;
  uuid: string;
  action_type_id: number;
  note_type_id: number;
  priority_type_id: number;
  note_bank_id: number;
  note_content: string;
  enabled: boolean;
  edit_locked: boolean;
  created_by_uuid: string;
  modified_by_uuid: string;
  created_date: string;
  modified_date: string;
  version_id: number;
  author_name: string;
}

export interface SaveUserNotePostData {
  recipientUuid: string;
  noteContent: string;
}

export interface SaveFlagNotePostData extends SaveUserNotePostData {
  flagUuid: string;
}

export const NOTE_INPUT_CHAR_MAX = 1000

const mockNotes = [
  "This is an example of a note. Notes can be left to describe reasons for actions taken on flags, or for any other reason of your choosing.",
  "Notes typically function as records for why flags have been resolved or otherwise modified. Notes can also be used for reminders to take future actions. Notes are not visible for students.",
  "Notes can be left with specific flags and can also be placed directly on student profiles in order to describe actions taken, reminders, etc., that may not necessarily be directly tied to a given flag."
]

export const createMockNote = () => ({
  id: Math.round(Math.random() * 100),
  uuid: uuidv4(),
  action_type_id: -1,
  note_type_id: -1,
  priority_type_id: -1,
  note_bank_id: -1,
  // Subtract Number.EPSILON to eliminate the chance that Math.random() equals 1
  note_content: mockNotes[Math.floor(Math.random() * (mockNotes.length - Number.EPSILON))],
  enabled: true,
  edit_locked: false,
  created_by_uuid: uuidv4(),
  modified_by_uuid: uuidv4(),
  created_date: new Date().toISOString(),
  modified_date: new Date().toISOString(),
  version_id: -1,
  author_name: "Mock Author",
})

export const createMockNotes = () => {
  const notes: Array<NoteDTO> = []
  const n = Math.round(Math.random() * 5)
  for(let i = 0; i < n; i++){
    notes.push(createMockNote())
  }
  return notes
}

export interface RequestUserNotesPayload {
  studentName: string;
  userUuid: string;
}

export interface RequestFlagNotesPayload extends RequestUserNotesPayload {
  flagName: string;
  flagUuid: string;
}
