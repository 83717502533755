
import { Enum } from '../../mesacloud/corejs'

export interface UserRole {
  matcher: RegExp;
  name: string;
  keyname: string;
}

export class UserRoleEnum extends Enum implements UserRole {

  protected constructor(public matcher: RegExp, public name: string, public keyname: string){ super() }

  public static DISTRICT = new UserRoleEnum(/district/i, "District", "district")
  public static CAMPUS = new UserRoleEnum(/teacher|campus/i, "Campus", "teacher")
  public static STUDENT = new UserRoleEnum(/student/i, "Student", "student")
  public static NA = new UserRoleEnum(/na/i, "N/A", "N/A")

  public static asArray(){
    return super.asArray() as Array<UserRole>
  }

  public static asObject(){
    return super.asObject() as { [key: string]: UserRole }
  }

  public static getByRoleName(roleName: string): UserRole {
    if(typeof roleName !== "string") return this.NA
    return this.asArray().find(role => !!((roleName + "").trim().match(role.matcher))) || this.NA
  }
}
