
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasMarginOption,
  HasEditableClassName,
  ExtendableProps
} from '../propsInterfaces'

interface LoadingRingPropTypes extends 
  HasMarginOption, 
  HasEditableClassName,
  ExtendableProps {
  seafoam?: boolean | null;
}

const LoadingRing = ({
  marginType,
  className,
  overrideDefaultClassName,
  seafoam,
  ...props
}: LoadingRingPropTypes) => {

  const [finalClassName, style] = useConfigurableStyle({
    defaultClassName: {
      "loading-ring": !seafoam,
      "loading-ring--seafoam": seafoam 
    },
    overrideDefaultClassName,
    additionalClassName: className,
    marginType
  })

  return (
    <div className={finalClassName} {...props} style={style}>
      <div/><div/><div/><div/>
    </div>
  )
}

export default LoadingRing
