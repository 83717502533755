import React from 'react'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  ExtendableProps
} from '../../propsInterfaces'

interface LeftnavHeadingPropTypes extends
  HasEditableClassName,
  ExtendableProps {
  text?: string;
}

const LeftnavHeading = ({
  text,
  className,
  overrideDefaultClassName,
  ...props
}: LeftnavHeadingPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "leftnav__heading",
    additionalClassName: className, 
    overrideDefaultClassName
  })

  return (
    <h3 className={finalClassName} {...props}>
      {text}
    </h3>
  )
}

export default LeftnavHeading
