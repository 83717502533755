
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useToggle, useClickOutside } from '../../../mesacloud/react-framework'

import { useSelector, userSagaActions } from '../../../store'

const HeaderProfile = () => {

  const [showProfile, toggleShowProfile] = useToggle(false)

  const clickRef = useRef(null)
  useClickOutside(clickRef, () => toggleShowProfile(false))

  const { whoami, userRole } = useSelector(({ user }) => user)

  const dispatch = useDispatch()

  const logout = () => dispatch(userSagaActions.fetchLogout())

  return (
    <div ref={clickRef}>
      <div className="header__item" onClick={toggleShowProfile}>
        <i className="fas fa-user-circle"/> 
        <span>{whoami.userName}</span> 
        <i className={showProfile ? "fas fa-caret-up" : "fas fa-caret-down"}/>
      </div>
      {showProfile && (
        <ul className="header__profile-list">
          <li className="header__profile-list__item">{whoami.firstName} {whoami.lastName}</li>
          <li className="header__profile-list__item">Email: {whoami.emailAddress}</li>
          <li className="header__profile-list__item">District: {whoami.districtName}</li>
          <li className="header__profile-list__item">Role: {userRole.name}</li>
          <li className="header__profile-list__item--logout" onClick={logout}>Logout</li>
        </ul>
      )}
    </div>
  )
}

export default HeaderProfile
