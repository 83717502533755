
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import MesaUI from '../mesacloud/react-framework'

import { AppPathEnum } from '../lib/enums'
import { UserRoleEnum } from '../lib/enums'

import Shell from '../components/layoutShell'
import PanelSwitch from '../components/panel'
import RouterBlocker from './RouterBlocker'
import IndicatorsPage from '../pages/indicators/Indicators'
import StudentPage from '../pages/student/Student'
import FourOFourPage from '../pages/FourOFour'
import FlagsPage from '../pages/flags'
import GroupsPage from '../pages/groups/Groups'
import ResourcesPage from '../pages/resources'
import {useSelector} from "../store";

const Router = () => {
  const userRole = useSelector(({ user: { userRole } }) => userRole);

  return (
    <RouterBlocker>
      <BrowserRouter>
        <Shell/>
        <PanelSwitch/>
        <MesaUI.Main withFullShell>
          <Routes>
            <Route
              path={AppPathEnum.HOME.path}
              element={
                userRole === UserRoleEnum.STUDENT ? <StudentPage/> : <IndicatorsPage/>
              }
            />
            <Route
              path={AppPathEnum.GROUPS.path}
              element={<GroupsPage/>}
            />
            <Route
              path={AppPathEnum.FLAGS.path}
              element={<FlagsPage/>}
            />
            <Route
              path={AppPathEnum.RESOURCES.path}
              element={<ResourcesPage/>}
            />
            <Route
              path={AppPathEnum.STUDENT.path}
              element={<StudentPage/>}
            />
            <Route
              path={AppPathEnum.FOUR_O_FOUR.path}
              element={<FourOFourPage/>}
            />
          </Routes>
        </MesaUI.Main>
      </BrowserRouter>
    </RouterBlocker>
  )
}

export default Router
