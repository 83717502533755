import { configureStore, Middleware } from "@reduxjs/toolkit"
import { createLogger } from 'redux-logger'
import createSagaMiddleware from "redux-saga"
import { MesaColorEnum } from '../mesacloud/corejs'

import { rootReducer } from './rootReducer'
import { rootSaga } from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

const middleware: Array<Middleware> = [sagaMiddleware]

let devTools = false
if(process.env.NODE_ENV !== "production"){
  devTools = true
  const logger = createLogger({
    collapsed: true,
    level: "info",
    colors: {
      title: () => MesaColorEnum.SEAFOAM.hex,
      prevState: () => MesaColorEnum.GOLD.hex,
      action: () => MesaColorEnum.BRICK.hex,
      nextState: () => MesaColorEnum.ORANGE.hex,
      error: () => MesaColorEnum.RED.hex,
    },
  }) as Middleware
  middleware.push(logger)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    // See https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
    serializableCheck: {
      ignoredPaths: [
        // PanelView instances from PanelViewEnum are stored within the store -
        // these are not serializable, so we ignore this error for now. To make
        // it serializable, PanelView instances should be converted to objects
        // when stored in the store, and then converted back to instances when
        // read from the store.
        'payload.view',
        'view.panelView',
        // UserRole instances from UserRoleEnum are stored within the store -
        // these are also not serializable. The same solution as above applies,
        // with the exception that the regexes in the UserRole enum are not
        // serializable. They should be stored as strings, and then converted
        // to regex using `new RegExp('string', 'i')` when read from the store.
        // This is only done within the method `UserRoleEnum.getByRoleName`.
        'user.userRole'
      ],
      ignoredActionPaths: ['payload.view', 'view.panelView', 'user.userRole']
    }
  }).concat(middleware),
  devTools: devTools
})

sagaMiddleware.run(rootSaga)
