
import React from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '../../../mesacloud/react-framework'

import { supportSagaActions, supportActions, viewActions, useSelector } from '../../../store'

import PanelSupportInput from './PanelSupportInput'
import PanelSupportCompletion from './PanelSupportCompletion'

const PanelSupport = () => {

  const {
    isSendingSupportMessage,
    supportMessageComplete,
    supportMessageFailed,
    supportMessageFailureReason
  } = useSelector(({ support }) => support)
  
  const dispatch = useDispatch()

  const sendSupportMessage = (text: string) => dispatch(supportSagaActions.sendSupportMessage({ feedbackMsg: text }))
  const reset = () => dispatch(supportActions.resetSupportMessageState())

  return (
    <>
      <MesaUI.PanelHeader>
        <h3>Make a Support Request</h3>
        <MesaUI.Button buttonType="close" onClick={() => dispatch(viewActions.closePanel())}/>
      </MesaUI.PanelHeader>
      <MesaUI.PanelContent className="panel__support">
        {supportMessageComplete && !supportMessageFailed ? (
          <PanelSupportCompletion reset={reset}/>
        ) : (
          <PanelSupportInput 
            sendSupportMessage={sendSupportMessage} 
            supportMessageFailed={supportMessageFailed} 
            supportMessageFailureReason={supportMessageFailureReason} 
            isLoading={isSendingSupportMessage}
          />
        )}
      </MesaUI.PanelContent>
    </>
  )
}

export default PanelSupport
