
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  HasConfigurableColors,
  ExtendableProps
} from '../propsInterfaces'

interface CardHeaderPropTypes extends
  HasEditableClassName,
  MaybeHasChildren,
  HasConfigurableColors, 
  ExtendableProps {
}

const CardHeader = ({
  className,
  overrideDefaultClassName,
  children,
  backgroundColor,
  borderColor,
  fontColor,
  ...props
}: CardHeaderPropTypes) => {

  const [finalClassName, style] = useConfigurableStyle({
    defaultClassName: "card__header",
    additionalClassName: className, 
    overrideDefaultClassName,
    backgroundColor,
    borderColor,
    fontColor,
    styleProp: props.style
  })

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  )
}

export default CardHeader