
import React from 'react'
import MesaUI from '../../../../mesacloud/react-framework'

import { GradPlanDetails } from '../../../../store/student/studentDomain'
import { useSelector } from '../../../../store'

interface StudentMainGPHeaderPropTypes {
  selectedGradPlan: GradPlanDetails | null;
  setSelectedGradPlan: (gp: GradPlanDetails | null) => void;
}

const StudentMainGPHeader = ({ 
  selectedGradPlan,
  setSelectedGradPlan
}: StudentMainGPHeaderPropTypes) => {

  const { 
    allGradPlans, 
    isLoadingAllGradPlans,
    isLoadingStudentProgressSnapshot,
    isLoadingStudentGradPlanStatus
  } = useSelector(({ student }) => student)

  const isLoading = isLoadingAllGradPlans || isLoadingStudentGradPlanStatus || isLoadingStudentProgressSnapshot

  return (
    <>
      <MesaUI.Container className="student__main__gp__header">
        <MesaUI.Container className="student__main__gp__header__left">
          <select 
            className="student__main__gp__header__select"
            value={selectedGradPlan?.masterGradPlanId}
            onChange={({ target: { value }}) => setSelectedGradPlan(allGradPlans.find(({ masterGradPlanId }) => masterGradPlanId + "" === value) || null)}
            style={!!selectedGradPlan?.name?.length ? { width: (Math.ceil(selectedGradPlan.name.length * (20/38)) + 3.25) + "em" } : {}}
          >
            {selectedGradPlan && Array.isArray(allGradPlans) && allGradPlans.map(({ masterGradPlanId, name }, i) => (
              <option 
                key={`student__main__gp__header__option-${i}-${masterGradPlanId}`}
                value={masterGradPlanId}
              >
                {name}
              </option>
            ))}
          </select>
          {isLoading && <MesaUI.LoadingRing seafoam/>}
        </MesaUI.Container>
        <MesaUI.Container className="student__main__gp__header__right">
          <MesaUI.Container className="student__main__gp__header__status">
          </MesaUI.Container>
        </MesaUI.Container>
      </MesaUI.Container>
    </>
  )
}

export default StudentMainGPHeader
