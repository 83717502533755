import { useEffect, useCallback, useMemo, useRef } from "react";

/*
  Ex: useWindowListener("click", (e) => { handleClickCallback(e) })

  Sets an event listener on window
  Only in effect while the component is mounted
*/
export const useWindowListener = <EventType extends Event>(eventName: string, callback: (e: EventType) => any) => {
  const _eventName = useMemo(() => eventName, [eventName]);
  const _callback = useCallback(callback, [callback]);

  const prevCallback = useRef(_callback) // need mutable value to properly switch out callbacks on change

  useEffect(() => {
    if(prevCallback.current !== _callback){
      window && window.removeEventListener(_eventName, prevCallback.current, false);
      prevCallback.current = _callback
    }
    window && window.addEventListener(_eventName, _callback, false);
    return () => {
      window && window.removeEventListener(_eventName, _callback, false);
    };
  }, [_eventName, _callback]);
};
