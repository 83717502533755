import React, { useState, useRef, useCallback } from 'react'
import ReactPaginate from "react-paginate"

import { useClickOutside, useRefListener } from '../../../hooks'

interface PaginationPropTypes {
  withPageJump?: boolean | null;
  page: number;
  totalPages: number;
  handlePageChange: (pageSelected: { selected: number }) => any;
}

const Pagination = ({
  withPageJump,
  page,
  totalPages,
  handlePageChange
}: PaginationPropTypes) => {

  const [displayPage, setDisplayPage] = useState(page + 1)

  const inputRef = useRef(null)

  const handleClickOutside = useCallback(() => {
    if(isNaN(displayPage) || typeof displayPage !== "number"){
      setDisplayPage(displayPage + 1)
    }
  }, [displayPage])

  useClickOutside(inputRef, handleClickOutside)

  const handleInputChange = useCallback(({ target: { value }}) => {
    const intVal = parseInt(value, 10)
    setDisplayPage(isNaN(intVal) ? value: intVal)
  }, [setDisplayPage])

  const handleSubmitPage = useCallback(() => {
    if(typeof displayPage !== "number" || isNaN(displayPage)) return

    let toPage = displayPage - 1
    if(displayPage > totalPages){
      setDisplayPage(totalPages)
      toPage = totalPages - 1
    }

    handlePageChange({ selected: toPage })
  }, [displayPage, setDisplayPage, handlePageChange])

  const handleSubmitWithEnter = useCallback((event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.key === "Enter") {
      handleSubmitPage();
    }
  }, [handleSubmitPage]);

  useRefListener(inputRef, 'keydown', handleSubmitWithEnter)
  
  return (
    <div className="pagination">
      {withPageJump && (
        <div className="pagination__jump">
          <div className="pagination__jump_message">Go To Page:</div>
          <input
            type="number"
            ref={inputRef}
            min="1"
            max={totalPages || 0}
            onChange={handleInputChange}
            value={displayPage}
          />
          <button
            className="pagination__jump__go"
            onClick={handleSubmitPage}
          >
            Go
          </button>
        </div>
      )}
      <ReactPaginate
        nextLabel={
          <i className="pagination__left fas fa-caret-right" style={{ fontSize: "1.25em" }}/>
        }
        previousLabel={
          <i className="pagination__right fas fa-caret-left" style={{ fontSize: "1.25em" }}/>
        }
        forcePage={page}
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={"pagination__react-paginate"}
        activeLinkClassName={"active"}
      />
    </div>
  )
}

export default Pagination
