
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps
} from '../propsInterfaces'

interface CardContentPropTypes extends
  HasEditableClassName,
  MaybeHasChildren, 
  ExtendableProps {
}

const CardContent = ({
  className,
  overrideDefaultClassName,
  children,
  ...props
}: CardContentPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: { "card__content": true }, 
    additionalClassName: className, 
    overrideDefaultClassName, 
  })

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  )
}

export default CardContent