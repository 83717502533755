
import React from 'react'

import { topographySrc, topographyTornSrc } from './imgSrcs/imgSrcs'

import { ExtendableProps } from '../propsInterfaces'

interface TopographyPropTypes extends ExtendableProps {
  torn?: boolean | null;
  size?: number | string;
}

const Topogrpahy = ({ torn, size, ...props }: TopographyPropTypes) => {

  const imgSrc = torn ? topographyTornSrc : topographySrc

  const style = size ? { "width": size } : {}

  const finalStyle = (typeof props.style === "object" && props.style !== null) ? (
    { style, ...props.style }
  ) : (
    style
  )

  return <img src={imgSrc} style={finalStyle} {...props}/>
}

export default Topogrpahy