import React from 'react'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

import {
  MaybeHasChildren,

  HasEditableClassName,
  ExtendableProps
} from '../../propsInterfaces'

interface LeftnavPropTypes extends
  MaybeHasChildren,
  HasEditableClassName,
  ExtendableProps {
}

const Leftnav = ({
  children,
  className,
  overrideDefaultClassName,
  
  ...props
}: LeftnavPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "leftnav",
    additionalClassName: className, 
    overrideDefaultClassName
  })

  return (
    <nav className={finalClassName} {...props}>
      {children}
    </nav>
  )
}

export default Leftnav
