
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { MesaColorEnum } from '../../../mesacloud/corejs'
import MesaUI from '../../../mesacloud/react-framework'

import StudentMainGP from './gradplan'
import StudentMainSchedule from './schedule'
import StudentMainCTE from './cte'
import StudentMainAssess from './assessments'
import StudentMainAck from './acknowledgements'
import StudentMainAch from './achievements'
import FlagsModal from '../common/FlagsModal'

import { StudentMainView } from '../studentMainView'

import { GradPlanDetails } from '../../../store/student/studentDomain'
import { useSelector, fileExportSagaActions } from '../../../store'
import { FlagStatusEnum } from '../../../lib/enums'
import { useStudentPageContext } from '../studentPageContext'

const viewHeaderSequence: Array<{ keyname: StudentMainView; title: string }> = [
  { keyname: "gradplans", title: "Grad Plans" },
  // { keyname: "assessments", title: "Assessments" },
  { keyname: "cte", title: "CTE Pathways" },
  // { keyname: "acknowledgements", title: "Acknowledgements"},
  // { keyname: "achievements", title: "Achievements"},
  { keyname: "schedule", title: "Schedule"},
]

const StudentMain = () => {

  const [view, setView] = useState<StudentMainView>("gradplans")
  const [showFlagsModal, setShowFlagsModal] = useState(false)
  const [selectedGradPlan, setSelectedGradPlan] = useState<GradPlanDetails | null>(null)

  const { studentId } = useStudentPageContext()

  const { studentInfo, isLoadingStudentInfo }  = useSelector(({ student }) => student)

  const dispatch = useDispatch()

  const { isExportingStudentTranscript } = useSelector(({ fileExport }) => fileExport)

  const {
    authRoleEnum
  } = useSelector(({user}) => user.whoami)
  const isNotStudent = authRoleEnum !== 'STUDENT'

  const exportTranscript = () => {
    if(!isExportingStudentTranscript && !isLoadingStudentInfo){
      dispatch(fileExportSagaActions.fetchStudentTranscriptExport({ studentId, studentName: studentInfo.studentName }))
    }
  }

  const nFlags = Array.isArray(studentInfo?.flags) ? studentInfo.flags.reduce((total, flag) => {
    if(flag?.status === FlagStatusEnum.UNRESOLVED.displayName){
      return total + 1
    }
    return total
  }, 0) : 0

  return (
    <MesaUI.Container className="student__main">
      <MesaUI.Container className="student__main__header">
        <MesaUI.Container className="student__main__header__tabs">
          {viewHeaderSequence.map(({ keyname, title }, i) => (
            <MesaUI.Container 
              key={`student-page-main-header-tab-${i}-${keyname}`} 
              className={{
                "student__main__header__tab": keyname !== view, 
                "student__main__header__tab--active": keyname === view,
              }}
              onClick={() => setView(keyname)}
            >
              {title}
            </MesaUI.Container>
          ))}
        </MesaUI.Container>
        <MesaUI.Container className="student__main__header__buttons no-mobile">
          { isNotStudent &&  
            <MesaUI.Button 
              className={{ "loading-whirl": isExportingStudentTranscript }} 
              buttonType="primary" 
              onClick={exportTranscript}
            >
              <i className="fas fa-file-download"/>
              Export Transcript
            </MesaUI.Button>
          }
          { isNotStudent &&
            <MesaUI.Container className="student__main__header__flags" onClick={() => setShowFlagsModal(true)}>
              <span>Flags</span><MesaUI.Container backgroundColor={nFlags > 0 ? MesaColorEnum.RED : MesaColorEnum.WHITE} fontColor={nFlags > 0 ? MesaColorEnum.WHITE : MesaColorEnum.MIDNIGHT}>{nFlags}</MesaUI.Container>
            </MesaUI.Container>
          }
          {showFlagsModal && <FlagsModal flags={studentInfo.flags || []} close={() => setShowFlagsModal(false)}/>}
        </MesaUI.Container>
      </MesaUI.Container>
      <MesaUI.Container className="student__main__content">
        {(() => {
          switch(view){
            case 'gradplans':
              return <StudentMainGP 
                      selectedGradPlan={selectedGradPlan} 
                      setSelectedGradPlan={setSelectedGradPlan} 
                      setView={setView}
                    />
            case 'schedule':
              return <StudentMainSchedule/>
            case 'cte':
              return <StudentMainCTE/>
            case 'assessments':
              return <StudentMainAssess/>
            case 'acknowledgements':
              return <StudentMainAck/>
            case 'achievements':
              return <StudentMainAch/>
            default:
              return <>Invalid</>
          }
        })()}
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default StudentMain
