
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import MesaUI, { useDocumentTitle, useToggle } from '../../mesacloud/react-framework'

import { useRedirectRoles } from '../../hooks'

import { useSelector, studentSagaActions, studentActions } from '../../store'

import StudentLeftPanel from './leftPanel'
import StudentMain from './main'
import { UserRoleEnum } from '../../lib/enums'

import { StudentPageContext } from './studentPageContext'

const Student = () => {

  useDocumentTitle("Mesa OnTime: Student")

  const urlStudentId = useParams<{ studentId?: string }>()?.studentId

  useRedirectRoles(urlStudentId ? [UserRoleEnum.STUDENT] : [])

  const { whoami: { id: userId } } = useSelector(({ user }) => user)
  const { allGradPlans }  = useSelector(({ student }) => student)

  const [studentId, setStudentId] = useState<string>(urlStudentId || userId)

  const [leftPanelCollapsed, toggleLeftPanelCollapsed] = useToggle(false)

  const dispatch = useDispatch()

  const loadInitialStudentData = (_studentId?: string) => {
    // All data that must be loaded for a student regardless of inner page view
    !allGradPlans?.length && dispatch(studentSagaActions.fetchAllGradPlans())
    dispatch(studentActions.reset())
    dispatch(studentSagaActions.fetchStudentInfo({ studentId: _studentId || studentId }))
    dispatch(studentSagaActions.fetchStudentEnrollment({ studentId: _studentId || studentId }))
  }

  // initial load
  useEffect(() => {
    loadInitialStudentData()
   // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(urlStudentId && urlStudentId !== studentId){
      dispatch(studentActions.reset())
      setStudentId(urlStudentId)
      loadInitialStudentData(urlStudentId)
    }
  // eslint-disable-next-line
  }, [urlStudentId, studentId])

  return (
    <StudentPageContext.Provider value={{ studentId, studentLeftPanelCollapsed: leftPanelCollapsed }}>
      <MesaUI.FlexRow className="student">
        <MesaUI.FlexCol col={1} className={{ "student__left": !leftPanelCollapsed, "student__left--collapsed": leftPanelCollapsed }}>
          <StudentLeftPanel isCollapsed={leftPanelCollapsed} toggleIsCollapsed={toggleLeftPanelCollapsed}/>
        </MesaUI.FlexCol>
        <MesaUI.FlexCol col={leftPanelCollapsed ? 10 : 3} className="student__right">
          <StudentMain/>
        </MesaUI.FlexCol>
      </MesaUI.FlexRow>
    </StudentPageContext.Provider>
  )
}

export default Student
