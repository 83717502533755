
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps,
  HasConfigurableColors,
  HasMarginAndPaddingOptions
} from '../propsInterfaces'

interface ButtonPropTypes extends
  HasEditableClassName,
  MaybeHasChildren, 
  HasConfigurableColors,
  HasMarginAndPaddingOptions,
  ExtendableProps {
  buttonType?: "primary" | "secondary" | "tertiary" | "close";
  disabled?: boolean | null;
  rectangular?: boolean | null;
}

const Button = ({
  children,
  className,
  overrideDefaultClassName,
  rectangular,
  buttonType,
  disabled,
  backgroundColor,
  borderColor,
  fontColor,
  marginType,
  paddingType,
  ...props
}: ButtonPropTypes) => {

  const [finalClassName, style] = useConfigurableStyle({
    defaultClassName: {
      "button": !rectangular && !disabled && !buttonType,
      "button--primary": buttonType === "primary",
      "button--secondary": buttonType === "secondary",
      "button--tertiary": buttonType === "tertiary",
      "button--close": buttonType === "close",
      "button--disabled": disabled,
      "button--rectangular": rectangular && buttonType !== "close",
    },
    additionalClassName: className,
    overrideDefaultClassName,
    backgroundColor,
    borderColor,
    fontColor,
    marginType,
    paddingType,
    styleProp: props.style
  })

  return (
    <button className={finalClassName} {...props} style={style}>
      {buttonType !== "close" && children}
    </button>
  )
}

export default Button