
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps,
  HasMarginOption
} from '../propsInterfaces'

export interface CardPropTypes extends
  HasEditableClassName,
  MaybeHasChildren,
  HasMarginOption,
  ExtendableProps {
}

const Card = ({
  className,
  overrideDefaultClassName,
  children,
  marginType,
  ...props
}: CardPropTypes) => {

  const [finalClassName, style] = useConfigurableStyle({
    defaultClassName: "card",
    additionalClassName: className, 
    overrideDefaultClassName,
    marginType,
    styleProp: props.style
  })

  return (
    <div className={finalClassName} {...props} style={style}>
      {children}
    </div>
  )
}

export default Card