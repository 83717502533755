
import React from 'react'
import MesaUI from "../../../mesacloud/react-framework"

import { normalizeGpaValue, getGpaRangeForGpaVal } from '../utils/gpaUtils'

interface StudentGPAPropTypes {
  rangeSet: string;
  value: number | string;
}

const StudentGPA = ({
  rangeSet,
  value
}: StudentGPAPropTypes) => {

  const range = getGpaRangeForGpaVal(value, rangeSet)

  if(!range.valid){
    console.warn(`Unable to get range for value "${value}" in GPA range set "${rangeSet}"`)
  }

  return (
    <MesaUI.FlexRow className="student-gpa">
      <MesaUI.Container className="student-gpa__num">{normalizeGpaValue(value, 2)}</MesaUI.Container>
      {range.valid && (
        <MesaUI.Container className="student-gpa__icon" fontColor={range.color}>
          <i className={range.iconClass}/>
        </MesaUI.Container>
      )}
    </MesaUI.FlexRow>
  )
}

export default StudentGPA
