
import React, { MouseEvent } from 'react'

import { useRootPortal } from '../_utils/useRootPortal'
import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import { 
  MaybeHasChildren, 
  HasEditableClassName, 
  ExtendableProps
} from '../propsInterfaces'

interface PanelPropTypes extends
  MaybeHasChildren,
  HasEditableClassName,
  ExtendableProps {
  isOpen?: boolean | null;
  withMask?: boolean | null;
  closeFunc?: (event: MouseEvent) => void;
}

const Panel = ({ 
  isOpen,
  withMask,
  closeFunc,
  className,
  overrideDefaultClassName,
  children,
  ...props
}: PanelPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: { 
      "panel": !withMask && !isOpen, 
      "panel--opened": isOpen,
      "panel--with-mask": withMask 
    }, 
    additionalClassName: className, 
    overrideDefaultClassName, 
  })

  return useRootPortal((
    <div className={finalClassName} {...props}>
      {withMask && (
        <div className="panel__mask" onClick={typeof closeFunc === "function" ? closeFunc : () => {}}/>
      )}
      <div className="panel__view">
        {children}
      </div>
    </div>
  ), "panel-root")
}

export default Panel