
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps,
  HasMarginAndPaddingOptions,
  HasConfigurableColors,
  HasBorderOption
} from '../propsInterfaces'

interface ContainerPropTypes extends
  HasEditableClassName,
  MaybeHasChildren, 
  ExtendableProps,
  HasMarginAndPaddingOptions,
  HasConfigurableColors,
  HasBorderOption {
}

const Container = ({
  children,
  className,
  overrideDefaultClassName,
  marginType,
  paddingType,
  hasBorder,
  backgroundColor,
  borderColor,
  fontColor,
  ...props
}: ContainerPropTypes) => {
  
  const [finalClassName, style] = useConfigurableStyle({
    defaultClassName: {
      "container": !hasBorder,
      "container--bordered": hasBorder
    },
    additionalClassName: className,
    overrideDefaultClassName,
    marginType,
    paddingType,
    backgroundColor,
    borderColor,
    fontColor,
    styleProp: props.style
  })

  return (
    <div className={finalClassName} {...props} style={style}>
      {children}
    </div>
  )
}

export default Container