
import React, { ReactNode } from 'react'

import { HasEditableClassName, MaybeHasChildren, ExtendableProps } from '../propsInterfaces'
import { MesaColor } from '../../../corejs'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'
import { useToggle } from '../../hooks'

interface CollapsibleListCardPropTypes extends 
  MaybeHasChildren,
  HasEditableClassName, 
  ExtendableProps {
  header?: ReactNode;
  defaultIsCollapsed?: boolean;
  headerBackgroundColor?: MesaColor | string;
  showMoreText?: string;
  showLessText?: string;
}

const CollapsibleListCard = ({
  className,
  overrideDefaultClassName,
  header,
  defaultIsCollapsed,
  headerBackgroundColor,
  showLessText,
  showMoreText,
  children,
  ...props
}: CollapsibleListCardPropTypes) => {

  const [isCollapsed, toggleIsCollapsed] = useToggle(typeof defaultIsCollapsed === "boolean" ? defaultIsCollapsed : true)

  const [finalClassName, style] = useConfigurableStyle({
    defaultClassName: {
      "collapsible-list-card": !isCollapsed,
      "collapsible-list-card--collapsed": isCollapsed
    },
    additionalClassName: className,
    overrideDefaultClassName,
    styleProp: props.style
  })

  const [headerClassName, headerStyle] = useConfigurableStyle({
    defaultClassName: "collapsible-list-card__header",
    backgroundColor: headerBackgroundColor
  })

  const [toggleClassName, toggleStyle] = useConfigurableStyle({
    defaultClassName: "collapsible-list-card__toggle__text",
    backgroundColor: headerBackgroundColor
  })

  return (
    <div className={finalClassName} style={style} {...props}>
      <div className={headerClassName} style={headerStyle}>
        {header || null}
      </div>
      <div className="collapsible-list-card__content">
        <div className="collapsible-list-card__toggle">
          <div className={toggleClassName} style={toggleStyle} onClick={toggleIsCollapsed}>
            {isCollapsed ? showMoreText || "Show More" : showLessText || "Show Less" }
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

CollapsibleListCard.defaultProps = {
  defaultIsCollapsed: true,
}

export default CollapsibleListCard
