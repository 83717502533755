export const debounce = (callback: Function, ms = 100, immediate = true) => {
  let timeout: NodeJS.Timeout | undefined

  return (...args: any) => {
    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      timeout = undefined;
      if (!immediate) {
        return callback.apply(this, args)
      }
    }, ms)

    if (callNow) return callback.apply(this, args)
  }
}
