import React from 'react'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

import {

  HasEditableClassName,
  ExtendableProps
} from '../../propsInterfaces'

interface LeftnavBreakPropTypes extends
  HasEditableClassName,
  ExtendableProps {
}

const LeftnavBreak = ({
  className,
  overrideDefaultClassName,
  ...props
}: LeftnavBreakPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "leftnav__break",
    additionalClassName: className, 
    overrideDefaultClassName
  })

  return (
    <div className={finalClassName}/>
  )
}

export default LeftnavBreak
