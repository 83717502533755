
import { Enum } from '../../mesacloud/corejs'

import { UserRoleEnum } from './UserRoleEnum'

export interface AppPath {
  path: string;
  exact: boolean;
  roles: Array<UserRoleEnum> | "*";
}

export class AppPathEnum extends Enum implements AppPath {

  protected constructor(public path: string, public roles: Array<UserRoleEnum> | "*", public exact = true){ super() }

  static readonly HOME = new AppPathEnum("/", "*")
  static readonly GROUPS = new AppPathEnum("/groups", [UserRoleEnum.DISTRICT, UserRoleEnum.CAMPUS])
  static readonly FLAGS = new AppPathEnum("/flags", [UserRoleEnum.DISTRICT, UserRoleEnum.CAMPUS])
  static readonly STUDENT = new AppPathEnum("/student/:studentId", [UserRoleEnum.DISTRICT, UserRoleEnum.CAMPUS])
  static readonly RESOURCES = new AppPathEnum("/resources", "*")
  static readonly FOUR_O_FOUR = new AppPathEnum("*", "*", false)

  static asObject(){
    return super.asObject() as { [keyname: string]: AppPath }
  }

  static asArray(){
    return super.asArray() as Array<AppPath>
  }
}
