
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps
} from '../propsInterfaces'

interface CardHeaderLeftPropTypes extends
  HasEditableClassName,
  MaybeHasChildren, 
  ExtendableProps { }

const CardHeaderLeft = ({
  className,
  overrideDefaultClassName,
  children,
  ...props
}: CardHeaderLeftPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "card__header__left", 
    additionalClassName: className, 
    overrideDefaultClassName
  })

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  )
}

export default CardHeaderLeft