
import React from 'react'

import MesaUI from '../../mesacloud/react-framework'

import { ENV } from '../../lib/env'

const Footer = () => (
  <MesaUI.Footer 
    defaultConfig={{ 
      appName: ENV.applicationName, 
      appVersion: ENV.versionName,
    }}
  />
)

export default Footer
