import React, { useRef } from 'react'
import MesaUI from '../mesacloud/react-framework'
import { useClickOutside, useToggle } from '../mesacloud/react-framework'

import { useSelector } from '../store'

const ErrorHistory = () => {

  const [displayHistory, toggleDisplayHistory] = useToggle(false)
  const clickRef = useRef(null)

  const { errorHistory } = useSelector(({ error }) => error)

  useClickOutside(clickRef, () => toggleDisplayHistory(false))

  const nErr = errorHistory?.length || 0

  const nErrStr = `${nErr} Error${nErr > 1 ? 's' : ''}`

  return (
    <div className="error-history" ref={clickRef}>
      {nErr > 0 && (
        <MesaUI.Container onClick={toggleDisplayHistory} className="error-history__label">
          <i className="fas fa-times-circle"/>
          <span>{nErrStr}</span>
        </MesaUI.Container>
      )}
      {displayHistory && (
        <MesaUI.Container className="error-history__dropdown">
          <MesaUI.Container paddingType="md" className="error-history__dropdown__title">
            {nErrStr} during this session
          </MesaUI.Container>
          <ul className="error-history__dropdown__list">
            {errorHistory.map(({ ts, errorMessage }, i) => (
              <li key={`error-history-item-${i}-${ts.toString()}`} className="error-history__dropdown__list__item">
                {ts.toLocaleString() + " - " + errorMessage}
              </li>
            ))}
          </ul>
        </MesaUI.Container>
      )}
    </div>
  )
}

export default ErrorHistory
