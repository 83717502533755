
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '../../../mesacloud/react-framework'

import { CreateGroupPayload, CreateGroupSpecialSelector, StudentGroup } from '../../../store/groups/groupsDomain'
import { useSelector } from '../../../store'

import { getInputStateFromGroup, emptyInputState, validateInputState, defaultSelector } from './createGroupUtils'
import { MesaColorEnum } from '../../../mesacloud/corejs'

import CreateGroupSelector from './CreateGroupSelector'
import CreateGroupSearch from './CreateGroupSearch'

import { groupsSagaActions } from '../../../store'

interface CreateGroupPropTypes {
  close: () => any;
  groupForEdit: StudentGroup | null;
}

const CreateGroup = ({
  close,
  groupForEdit,
}: CreateGroupPropTypes) => {

  const [inputState, setInputState] = useState<CreateGroupPayload>(emptyInputState)
  const [inputStateValid, setInputStateValid] = useState(false)
  const [inputError, setInputError] = useState("")
  const [showHelp, setShowHelp] = useState<boolean>(false)

  const dispatch = useDispatch()

  const {
    isCreatingGroup
  } = useSelector(({ groups }) => groups)

  const submitGroup = () => {
    if(inputStateValid){
      dispatch(groupsSagaActions.createGroup(inputState))
    }
  }

  const isEdit = !!groupForEdit

  const updateInputState = (update: Partial<CreateGroupPayload>) => {
    setInputState({ ...inputState, ...update })
  }

  useEffect(() => {
    setInputState(getInputStateFromGroup(groupForEdit))
  // eslint-disable-next-line
  }, [groupForEdit])

  useEffect(() => {
    const [isValid, err] = validateInputState(inputState, groupForEdit)
    setInputStateValid(isValid)
    setInputError(err)
  // eslint-disable-next-line
  }, [inputState, groupForEdit])

  useEffect(() => {
    return () => {
      if(isCreatingGroup){
        close()
      }
    }
  // eslint-disable-next-line
  }, [isCreatingGroup])

  useEffect(() => {
    setShowHelp(false);
  }, []);

  const addSelector = () => updateInputState({ specialSelectors: [...inputState.specialSelectors, defaultSelector] })
  const updateSelector = (index: number, update: Partial<CreateGroupSpecialSelector>) => {
    const copy = inputState.specialSelectors.slice()
    copy.splice(index, 1, { ...copy[index], ...update })
    updateInputState({ specialSelectors: copy })
  }
  const deleteSelector = (index: number) => {
    const copy = inputState.specialSelectors.slice()
    copy.splice(index, 1)
    updateInputState({ specialSelectors: copy })
  }

  const setName = (name: string) => updateInputState({ name: name.replace(/[^A-Za-z0-9\s]/gi,'').slice(0,30) })

  const addStudent = (studentId: string) => updateInputState({ studentIds: [...inputState.studentIds, studentId] })
  const removeStudent = (studentId: string) => {
    const copy = inputState.studentIds.slice()
    const existingIndex = copy.findIndex(_sId => _sId === studentId)
    if(existingIndex !== -1){
      copy.splice(existingIndex, 1)
    }
    updateInputState({ studentIds: copy })
  }

  return (
    <MesaUI.Modal closeFunc={() => !isCreatingGroup && close()}>
      <MesaUI.Card className={{ "create-group": true, 'loading-whirl': isCreatingGroup }}>
        <MesaUI.CardHeader backgroundColor={MesaColorEnum.NAVY} fontColor={MesaColorEnum.WHITE}>
          <MesaUI.CardHeaderLeft>
            <h3>{isEdit ? `Edit Group: ${groupForEdit?.groupName}` : 'New Group'}</h3>
            {!showHelp && <i className={"fa fa-question-circle group-circle"} onClick={() => {setShowHelp(true)}}></i>}
            {!showHelp && <button onClick={() => {setShowHelp(true)}} id="group-instructions-button">Instructions</button>}
          </MesaUI.CardHeaderLeft>
          <MesaUI.CardHeaderRight>
            <MesaUI.Button buttonType="close" onClick={close}/>
          </MesaUI.CardHeaderRight>
        </MesaUI.CardHeader>
        <MesaUI.CardContent>
          { showHelp &&
            <div className="group-instructions-tooltip">
              <div className="group-instructions-tooltip-header">
                <h1>Instructions</h1>
                <MesaUI.Button buttonType="close" className="tooltip-close" onClick={() => {setShowHelp(false)}}/>
              </div>
              <ul>
                <li>Groups will not automatically refresh with caseload updates.</li>
                <li>If you need to add or remove a student from an existing Group, simply click that Group’s “View” button to bring up the caseload.</li>
                <li>To remove a student search the “Students” tab and click the trashcan icon.</li>
                <li>To add a student, search using the  “Add” tab and click the plus icon.</li>
              </ul>
            </div>}
          <MesaUI.Container className="create-group__name">
            <input type="text" value={inputState.name} onChange={({ target: { value }}) => setName(value)} placeholder="Enter a Group Name"/>
          </MesaUI.Container>
          <MesaUI.Container className="create-group__selectors">
            {inputState.specialSelectors.map((selector, i) => (
              <CreateGroupSelector
                key={`create-group-selector-${i}`}
                selector={selector}
                updateSelector={(update: Partial<CreateGroupSpecialSelector>) => { updateSelector(i, update) }}
                remove={() => deleteSelector(i)}
              />
            ))}
          </MesaUI.Container>
          <MesaUI.Container className="create-group__add-selector" onClick={() => addSelector()}>
            + Add Students By YE9/Campus/Last Name
          </MesaUI.Container>
          {!isEdit && <CreateGroupSearch studentIds={inputState.studentIds} removeStudent={removeStudent} addStudent={addStudent}/>}
          {(isEdit && groupForEdit?.singleStudentsAdded) ? (
            <MesaUI.Container className="create-group__reset-single">
              <input 
                type="checkbox" 
                checked={inputState.resetSingleAddedStudents}
                onChange={() => updateInputState({ resetSingleAddedStudents: !inputState.resetSingleAddedStudents })}
              /> Clear all individually added students ({groupForEdit?.studentCntMessage})
            </MesaUI.Container>
          ) : isEdit && (
            <MesaUI.Container paddingType="md">
              This group has no individually added students.
            </MesaUI.Container>
          )}
          {!inputStateValid ? (
            <MesaUI.Container className="create-group__message">
              {inputError}
            </MesaUI.Container>
          ) : (
            <MesaUI.Container className="create-group__submit">
              <MesaUI.Button buttonType="primary" onClick={submitGroup}>{isEdit ? "Edit Group" : "Create Group"}</MesaUI.Button>
            </MesaUI.Container>
          )}
        </MesaUI.CardContent>
      </MesaUI.Card>
    </MesaUI.Modal>
  )
}

export default CreateGroup
