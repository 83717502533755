import React from 'react'
import { useDocumentTitle } from "./mesacloud/react-framework"

import './assets/sass/style.scss'

import ErrorBoundary from './components/ErrorBoundary'

import { StoreProvider } from './store'

import Router from './router/Router'

const App = () => {
  
  useDocumentTitle("Mesa OnTime")

  return (
    <ErrorBoundary>
      <StoreProvider>
        <Router/>
      </StoreProvider>
    </ErrorBoundary>
  )
}

export default App;
