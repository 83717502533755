
import React from 'react'

import { useRootPortal } from '../../_utils/useRootPortal'

import { HasChildren } from '../../propsInterfaces'

const ShellRoot = ({ children }: HasChildren): React.ReactPortal => useRootPortal((
  <div className="shell">
    {children}
  </div>
), "shell-root")

export default ShellRoot