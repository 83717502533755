
import React from 'react'

import { useConfigurableStyle } from '../../_utils/useConfigurableStyle'

import {
  MaybeHasChildren,
  HasEditableClassName
} from '../../propsInterfaces'

interface HeaderRightPropTypes extends
  MaybeHasChildren,
  HasEditableClassName {
}

const HeaderRight = ({
  children,
  className,
  overrideDefaultClassName,
}: HeaderRightPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "header__right", 
    additionalClassName: className, 
    overrideDefaultClassName
  })

  return (
    <div  className={finalClassName}>
      {children}
    </div>
  )
}

export default HeaderRight