export const createDatedFilename = (baseFileName: string, fileExtensionNoDot: string): string => {

  if(!baseFileName || typeof baseFileName !== "string"){
    baseFileName = "unnamed"
  }

  if(!fileExtensionNoDot || typeof fileExtensionNoDot !== "string"){
    fileExtensionNoDot = "file"
  }

  const dateString = new Date().toLocaleDateString().replace(/[^\dA-Za-z]/, "-") 

  return `${baseFileName}-${dateString}.${fileExtensionNoDot}`
}

export const downloadCSV = (textCsvData: string, fileName: string): void => {
  const dataBlob = new Blob([textCsvData], { type: "text/csv" });
  const objectURL = window.URL.createObjectURL(dataBlob);

  const link = document.createElement("a");

  link.setAttribute("href", objectURL);
  link.setAttribute("download", fileName);
  document.body && document.body.appendChild(link);

  link.click();
}

export const downloadCSVFromBase64 = (textCsvData: string, fileName: string): void => {
  const decodedData = atob(textCsvData);

  downloadCSV(decodedData, fileName);
}
