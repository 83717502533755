
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import { LoadingRing } from '../loading'

import {
  HasEditableClassName,
  HasFontColorOption,
  HasBorderColorOption,
  HasMarginOption,
  ExtendableProps,
  MaybeHasChildren
} from '../propsInterfaces'

interface SearchPropTypes extends 
  HasEditableClassName,
  HasFontColorOption,
  HasBorderColorOption,
  HasMarginOption,
  MaybeHasChildren,
  ExtendableProps {
  placeholder?: string;
  searchText?: string;
  isLoading?: boolean | null;
  showResults?: boolean | null;
  onTextChange?: (text: string) => any;
}

const Search = ({
  className,
  overrideDefaultClassName,
  marginType,
  fontColor,
  borderColor,
  children,
  placeholder,
  searchText,
  onTextChange,
  isLoading,
  showResults,
  ...props
}: SearchPropTypes) => {

  const [finalClassName, style] = useConfigurableStyle({
    defaultClassName: "search",
    additionalClassName: className,
    overrideDefaultClassName,
    marginType,
    fontColor,
    borderColor,
    styleProp: props.style
  })

  return (
    <div className={finalClassName} {...props} style={style}>
      <div className="search__icon">
        {isLoading ? (
          <LoadingRing seafoam/>
        ) : (
          <i className="far fa-search"/>
        )}
      </div>
      <input 
        type="search" 
        placeholder={placeholder || "Search"} 
        className="search__input" 
        value={searchText}
        onChange={typeof onTextChange === "function" ? (e) => { onTextChange(e?.target?.value || "") } : () => {}}
      />
      <ul className="search__result-list" style={showResults ? {} : { display: "none" }}>
        {children}
      </ul>
    </div>
  )
}

export default Search