import { useState, useCallback } from "react";

/* 

  useToggle

  Ex: const [isToggled, toggleIsToggled] = useToggle()

  Usage:
    useToggle(initial: bool = false)

  A quick util for a state toggle.

  Optional "initial" param sets initial value (default false)
  
  toggle function by default flips the state value,
  but can force a bool value, if one is passed

*/

export const useToggle = (initial = false) => {
  const [isToggled, setIsToggled] = useState<boolean>(initial);

  const toggle = useCallback(
    value => {
      setIsToggled(typeof value === "boolean" ? value : !isToggled);
    },
    [isToggled]
  );

  return [isToggled, toggle] as [boolean, typeof toggle];
};
