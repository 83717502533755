
import { MesaColor } from "../../../corejs"

export const useColorStyle = ({
  backgroundColor,
  fontColor,
  borderColor
}: {
  backgroundColor?: MesaColor | string;
  fontColor?: MesaColor | string;
  borderColor?: MesaColor | string;
}): [Array<string>, { [styleAttr: string]: string }] => {

  const classNames: any[] = [];
  const style: { [styleAttr: string]: string } = { }

  if(typeof backgroundColor === "string"){
    style.backgroundColor = backgroundColor
  } else if(backgroundColor && typeof backgroundColor === "object" && backgroundColor.backgroundClassName) {
    classNames.push(backgroundColor.backgroundClassName)
  }
  if(typeof fontColor === "string"){
    style.color = fontColor
  } else if(fontColor && typeof fontColor === "object" && fontColor.fontClassName) {
    classNames.push(fontColor.fontClassName)
  }
  if(typeof borderColor === "string"){
    style.borderColor = borderColor
  } else if(borderColor && typeof borderColor === "object") {
    classNames.push(borderColor.borderClassName)
  }

  return [classNames, style]
}
