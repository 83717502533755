
import React from 'react'
import MesaUI from '../../mesacloud/react-framework'

import { StudentGroup } from '../../store/groups/groupsDomain'

interface GroupsSavedGroupPropTypes {
  group: StudentGroup;
  viewGroup: () => void;
  editGroup: () => void;
  viewDetails: () => void;
  deleteGroup: () => void;
}

const GroupsSavedGroup = ({
  group,
  viewGroup,
  editGroup,
  viewDetails,
  deleteGroup
}: GroupsSavedGroupPropTypes) => {

  return (
    <MesaUI.Container className="groups-card__saved-group" paddingType="sm" style={{ width: "100%" }}>
      <MesaUI.FlexRow style={{ justifyContent: "space-between", width: "100%" }}>
        <MesaUI.FlexCol className="groups-card__saved-group__name">
          {!group?.hasDetails ? '*' : ''}{group?.groupName || "N/A"}
        </MesaUI.FlexCol>
        <MesaUI.FlexCol>
          <MesaUI.FlexRow style={{ justifyContent: "flex-end" }}>
            <MesaUI.Button buttonType="primary" onClick={viewGroup}>View</MesaUI.Button>
            <MesaUI.Button buttonType="primary" disabled={group?.autogen || !group?.hasDetails} onClick={editGroup}>Edit</MesaUI.Button>
            <MesaUI.Button buttonType="primary" disabled={group?.autogen || !group?.hasDetails} onClick={viewDetails}>Details</MesaUI.Button>
            <MesaUI.Button buttonType="primary" disabled={group?.autogen} onClick={deleteGroup}>Remove</MesaUI.Button>
          </MesaUI.FlexRow>
        </MesaUI.FlexCol>
      </MesaUI.FlexRow>
    </MesaUI.Container>
  )
}

export default GroupsSavedGroup
