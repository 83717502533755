
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from "../../../../mesacloud/react-framework"

import { useSelector, studentSagaActions } from '../../../../store'
import { useStudentPageContext } from '../../studentPageContext'
import { isNaLike } from '../../../../mesacloud/corejs'

const StudentMainAssess = () => {

  const {
    studentAssessmentStatuses,
    isLoadingStudentAssessmentStatuses
  } = useSelector(({ student }) => student)

  const { studentId } = useStudentPageContext()

  const dispatch = useDispatch()

  useEffect(() => {
    studentId && dispatch(studentSagaActions.fetchStudentAssessmentsStatuses({ studentId }))
  }, [studentId, dispatch])

  return (
    <MesaUI.Container className="student__main__generic-list student__main__assess">
      <MesaUI.Container className="student__main__generic-list__header">
        <MesaUI.FlexRow>
          <MesaUI.FlexCol col={1}>
            Status
          </MesaUI.FlexCol>
          <MesaUI.FlexCol col={3}>
            Assessment
          </MesaUI.FlexCol>
          <MesaUI.FlexCol col={1}>
            Result*
          </MesaUI.FlexCol>
        </MesaUI.FlexRow>
      </MesaUI.Container>
      <MesaUI.Container className="student__main__generic-list__list">
        {isLoadingStudentAssessmentStatuses ? (
          <MesaUI.Container paddingType="xl"><MesaUI.LoadingRing seafoam/></MesaUI.Container>
        ) : (
          Array.isArray(studentAssessmentStatuses) && studentAssessmentStatuses.map(({ eocTypeName, status, result }, i) => (
            <MesaUI.FlexRow 
              key={`student__main__generic-list__courses__item-${i}`}
              className="student__main__generic-list__list__item"
            >
              <MesaUI.FlexCol col={1} className="student__main__assess__status">
                <i className={(status === false || isNaLike(result)) ? "fas fa-minus-circle" : "fas fa-check-circle"}/>
              </MesaUI.FlexCol>
              <MesaUI.FlexCol col={3}>
                {eocTypeName}
              </MesaUI.FlexCol>
              <MesaUI.FlexCol col={1}>
                {result}
              </MesaUI.FlexCol>
            </MesaUI.FlexRow>
          ))
        )}
        {!isLoadingStudentAssessmentStatuses && (
          <MesaUI.FlexRow className="student__main__generic-list__list__item">
            <MesaUI.FlexCol>
              * Mesa displays a student&apos;s highest score out of all attempts 
            </MesaUI.FlexCol>
          </MesaUI.FlexRow>
        )}
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default StudentMainAssess
