
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import { 
  MaybeHasChildren, 
  HasEditableClassName, 
  ExtendableProps
} from '../propsInterfaces'

interface FlexColPropTypes extends
  MaybeHasChildren,
  HasEditableClassName,
  ExtendableProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
}

const FlexCol = ({ 
  className,
  overrideDefaultClassName,
  children,
  col,
  ...props
}: FlexColPropTypes) => {

  const validCol = typeof col === "number" && (col <= 16 && col >= 1)

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: { "flex-col--1": !validCol, [`flex-col--${col}`]: validCol },
    additionalClassName: className, 
    overrideDefaultClassName, 
  })

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  )
}

FlexCol.defaultProps = {
  col: 1
}

export default FlexCol