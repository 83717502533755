

import React from 'react'
import { NavLink } from 'react-router-dom'
import MesaUI from '../../../mesacloud/react-framework'

import { UserRoleEnum } from '../../../lib/enums'
import RoleFilter from '../../RoleFilter'

import ErrorHistory from '../../ErrorHistory'
import HeaderSearch from './HeaderSearch'
import HeaderExports from './HeaderExports'
import HeaderErrorMessage from './HeaderErrorMessage'
import HeaderProfile from './HeaderProfile'

const Header = () => (
  <MesaUI.Header>
    <MesaUI.HeaderLeft>
      <NavLink to="/" style={{ display: "flex", alignItems: "center" }}>
        <MesaUI.Container marginType={{ left: "1.25em", right: "md", bottom: "-2px" }}>
          <MesaUI.Logo size="62px" logoType="triangles-svg"/>
        </MesaUI.Container>
        <h1>Mesa OnTime</h1>
      </NavLink>
    </MesaUI.HeaderLeft>
    <MesaUI.HeaderRight>
      <ErrorHistory/>
      <RoleFilter roles={[UserRoleEnum.STUDENT]} exclude>
        <HeaderSearch/>
      </RoleFilter>
      <RoleFilter roles={[UserRoleEnum.STUDENT]} exclude>
        <HeaderExports/>
      </RoleFilter>
      <HeaderProfile/>
    </MesaUI.HeaderRight>
    <HeaderErrorMessage/>
  </MesaUI.Header>
)

export default Header
