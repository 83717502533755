import React,{ Component, ReactNode } from 'react'

import { store, errorActions } from '../store'

import ErrorPage from '../pages/ErrorPage'

export default class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean; errorMessage: string; errorInfo: string}> {

  constructor(props: { children: ReactNode }){
    super(props)
    this.state = { hasError: false, errorMessage: "", errorInfo: "" }
  }

  static getDerivedStateFromError(error: Error){
    return { hasError: true, errorMessage: error + "", errorInfo: "" }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo){
    store.dispatch(errorActions.displayError({ errorMessage: `${error} (Info: ${errorInfo.componentStack})` }))
    this.setState({ errorMessage: error + "", errorInfo: errorInfo.componentStack ?? '' })
  }

  render(){
    return this.state.hasError ? (
      <ErrorPage 
        errorMessage={this.state.errorMessage} 
        errorInfo={this.state.errorInfo} 
      />
    ) : this.props.children || null
  }
}
