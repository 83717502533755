
import React, { useMemo } from 'react'

import { mesaLogoSrc, trianglesLogoSrc, trianglesLogoSVGSrc } from './imgSrcs/imgSrcs'

import { ExtendableProps } from '../propsInterfaces'

interface LogoPropTypes extends ExtendableProps {
  logoType?: "mesa" | "triangles" | "triangles-svg";
  size?: number | string;
}

const Logo = ({ logoType, size, ...props }: LogoPropTypes) => {

  const imgSrc = useMemo(() => {
    switch(logoType){
      case "mesa":
        return mesaLogoSrc
      case "triangles":
        return trianglesLogoSrc
      case "triangles-svg":
        return trianglesLogoSVGSrc
      default:
        return mesaLogoSrc
    }
  }, [logoType])

  const style = size ? { "width": size } : {}

  const finalStyle = (typeof props.style === "object" && props.style !== null) ? (
    { style, ...props.style }
  ) : (
    style
  )

  return <img src={typeof imgSrc === "object" ? imgSrc.default.toString() : imgSrc} style={finalStyle} {...props}/>
}

Logo.defaultProps = { logoType: "mesa" }

export default Logo
