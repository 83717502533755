import createClassName from 'classnames'

type ClassNamesArgObject = { [key: string]: boolean | undefined | null }
export type ClassNamesArg = string | Array<string | ClassNamesArgObject> | ClassNamesArgObject

export const useEditableClassName = (
  defaultClassName: ClassNamesArg,
  classNamesOptions?: ClassNamesArg,
  overrideDefaultClassName?: boolean | null
) => {
  const classNames: any[] = [];

  if(!overrideDefaultClassName){
    classNames.push(defaultClassName)
  }

  if(classNamesOptions){
    classNames.push(classNamesOptions)
  }

  return createClassName(classNames)
}
