
import React from 'react'

import MesaUI from '../../mesacloud/react-framework'

import { AppPathEnum } from '../../lib/enums'
import { UserRoleEnum } from '../../lib/enums'
import { useSelector } from '../../store'

import RoleFilter from '../RoleFilter'

const Leftnav = () => {
  const {
    authRoleEnum
  } = useSelector(({user}) => user.whoami)
  const isNotStudent = authRoleEnum !== 'STUDENT'

  return (
    <MesaUI.Leftnav>
      <RoleFilter roles={[UserRoleEnum.STUDENT]} exclude>
        <MesaUI.LeftnavItem 
          isLinkTo={AppPathEnum.HOME.path}
          exact={AppPathEnum.HOME.exact.toString()}
          iconFaClass="fas fa-chart-pie" 
          labelText="Indicators"
          autoActivateWithLink
        />
        <MesaUI.LeftnavItem 
          isLinkTo={AppPathEnum.GROUPS.path}
          exact={AppPathEnum.GROUPS.exact.toString()}
          iconFaClass="fas fa-users" 
          labelText="Manage Groups" 
          autoActivateWithLink
        />
        <MesaUI.LeftnavItem 
          isLinkTo={AppPathEnum.FLAGS.path}
          exact={AppPathEnum.GROUPS.exact.toString()}
          iconFaClass="fab fa-font-awesome-flag" 
          labelText="Manage Flags" 
          autoActivateWithLink
        />
      </RoleFilter>
      <RoleFilter roles={[UserRoleEnum.STUDENT]}>
        <MesaUI.LeftnavItem 
          isLinkTo={AppPathEnum.HOME.path}
          exact={AppPathEnum.HOME.exact.toString()}
          iconFaClass="fas fa-clipboard-list-check" 
          labelText="Home"
          autoActivateWithLink
        />
      </RoleFilter>
      <MesaUI.LeftnavBreak/>
      { isNotStudent &&
        <MesaUI.LeftnavItem 
          isLinkTo={AppPathEnum.RESOURCES.path}
          exact={AppPathEnum.GROUPS.exact.toString()}
          iconFaClass="fas fa-headphones" 
          labelText="Additional Resources" 
          autoActivateWithLink
        />
      }    
    </MesaUI.Leftnav>
  )
}

export default Leftnav
