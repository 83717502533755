import { v4 as uuidv4 } from 'uuid'
import { FlagDTO, mockFlags } from '../flags/flagsDomain'

/*
  Student Info
*/

export interface StudentInfo {
  cohort: string;
  dateOfBirth: string;
  externalStudentId: string;
  flagLinkMessage: string;
  flags: Array<FlagDTO>;
  race: string;
  riskAssessmentStatus: string;
  school: string;
  studentId: string;
  studentName: string;
  systemUserUuid: string;
  transcriptCreditSum: number;
  studentWeightedGpa: number;
  studentUnweightedGpa: number;
  districtHasGpa: boolean;
  gpaRangeSet: string;
}

export const emptyStudentInfo: StudentInfo = {
  cohort: "",
  dateOfBirth: "",
  externalStudentId: "",
  flagLinkMessage: "",
  flags: [],
  race: "",
  riskAssessmentStatus: "",
  school: "",
  studentId: "",
  studentName: "",
  systemUserUuid: "",
  transcriptCreditSum: -1,
  studentUnweightedGpa: -1,
  studentWeightedGpa: -1,
  districtHasGpa: false,
  gpaRangeSet: ""
}

export const mockStudentInfo: StudentInfo = {
  cohort: "Mock Cohort",
  dateOfBirth: "Mock Date of Birth",
  externalStudentId: "012345",
  flagLinkMessage: "View Flags",
  flags: mockFlags,
  race: "Mock Race",
  riskAssessmentStatus: "On Track",
  school: "Mock School",
  studentId: "012345",
  studentName: "Mock Student name",
  systemUserUuid: uuidv4(),
  transcriptCreditSum: 24.5,
  studentUnweightedGpa: 2.5,
  studentWeightedGpa: 5.0,
  districtHasGpa: true,
  gpaRangeSet: '"0.0-2.0:RED","2.0-2.25:YELLOW","2.25-10.0:GREEN"'
}

/* 
  Student Grad Plan
*/

export interface CourseGroupStatus {
  sectionId: string;
  sectionReqId: string;
  sectionReqStatus: string;
}

export interface CourseDetails {
  academicSession: string;
  courseGrade: string;
  courseName: string;
  courseTierSeq: string;
  creditEarned: string;
  groupId?: string;
  seqOrder: number;
  stateCourseNum: string;
  subtype: string;
  letterGrade: string;
}

export const emptyCourseDetails: CourseDetails = {
  academicSession: "",
  courseGrade: "",
  courseName: "",
  courseTierSeq: "",
  creditEarned: "",
  letterGrade: "",
  groupId: "",
  seqOrder: 0,
  stateCourseNum: "",
  subtype: "",
}

export const mockCourses: Array<CourseDetails> = [
  { academicSession: "YR", courseGrade: "86.5", letterGrade: "A", courseName: "Mock Course 1", courseTierSeq: "", creditEarned: "1.0", groupId: "0", seqOrder: 0, stateCourseNum: "000000", subtype: "" },
  { academicSession: "YR", courseGrade: "86.5", letterGrade: "N/A", courseName: "Mock Course 2", courseTierSeq: "", creditEarned: "1.0", groupId: "0", seqOrder: 0, stateCourseNum: "000001", subtype: "" },
  { academicSession: "YR", courseGrade: "86.5", letterGrade: "B+", courseName: "Mock Course 3", courseTierSeq: "", creditEarned: "1.0", groupId: "0", seqOrder: 0, stateCourseNum: "000002", subtype: "" },
  { academicSession: "YR", courseGrade: "86.5", letterGrade: "C-", courseName: "Mock Course 4", courseTierSeq: "", creditEarned: "1.0", groupId: "0", seqOrder: 0, stateCourseNum: "000003", subtype: "" },
]

export interface CourseGroupDetails {
  academicSession: string;
  courseAssignedCredit: number;
  courseName: string;
  details: Array<CourseDetails>;
  gradPlanName: string;
  sectionAbbv: string | null;
  sectionReqAbbv: string | null;
  sectionId: string | null;
  sectionRequirementId: string | null;
  sectionStatusMap: { [sectionId: number]: Array<CourseGroupStatus> };
  status: string;
  subject: string;
  disclaimers: Array<string>;
}

export const emptyCourseGroup: CourseGroupDetails = {
  academicSession: "N/A",
  courseAssignedCredit: 0,
  courseName: "N/A",
  details: [],
  gradPlanName: "N/A",
  sectionAbbv: "N/A",
  sectionReqAbbv: "N/A",
  sectionId: "N/A",
  sectionRequirementId: "N/A",
  sectionStatusMap: {},
  status: "N/A",
  subject: "N/A",
  disclaimers: []
}

const mockCourseGroup: CourseGroupDetails = {
  academicSession: "YR",
  courseAssignedCredit: 2,
  courseName: "Mock Course",
  details: mockCourses,
  disclaimers: [],
  gradPlanName: "",
  sectionAbbv: null,
  sectionId: null,
  sectionReqAbbv: null,
  sectionRequirementId: null,
  sectionStatusMap: {},
  status: "Scheduled", 
  subject: "English"
}

export const mockCourseGroups: Array<CourseGroupDetails> = [
  { ...mockCourseGroup, status: "Scheduled", courseName: "Mock Course 1" },
  { ...mockCourseGroup, status: "Scheduled At Risk", courseName: "Mock Course 2" },
  { ...mockCourseGroup, status: "Completed", courseName: "Mock Course 3" },
  { ...mockCourseGroup, status: "Partial", courseName: "Mock Course 4" },
  { ...mockCourseGroup, subject: "Math", status: "Required", courseName: "Mock Course 5" },
  { ...mockCourseGroup, details: [], subject: "Math", status: "Future Requirement", courseName: "Mock Course 6" },
  { ...mockCourseGroup, subject: "Math", status: "Not Completed", courseName: "Mock Course 7" },
]

export interface GradPlanDetails {
  districtAssignedGradPlanId: string;
  masterGradPlanId: string;
  name: string;
}

export const mockGradPlans: Array<GradPlanDetails> = [
  { districtAssignedGradPlanId: "-1", masterGradPlanId: "-1", name: "Mock Grad Plan 1" },
  { districtAssignedGradPlanId: "-2", masterGradPlanId: "-2", name: "Mock Grad Plan 2" }
]

/*
  Student Progress Snapshot
*/

export interface StudentProgressSnapshotItem {
  id: number;
  requiredSectionCredits: string;
  studentEarnedCredits: string;
  subjectName: string;
}

export const mockStudentProgressSnapshotItems: Array<StudentProgressSnapshotItem> = [
  { id: -1, requiredSectionCredits: "1.0", studentEarnedCredits: "0.5", subjectName: "English" },
  { id: -2, requiredSectionCredits: "2.0", studentEarnedCredits: "1.5", subjectName: "Math" },
  { id: -3, requiredSectionCredits: "3.0", studentEarnedCredits: "3.0", subjectName: "Mock Subject" },
]

/* 
  Student Assessments
*/

export interface StudentAssessmentStatus {
  status: boolean;
  result: string | number;
  eocTypeName: string;
}

export const mockStudentAssessmentStatuses: Array<StudentAssessmentStatus> = [
  { status: false, result: "500", eocTypeName: "Mock Assessment" },
  { status: true, result: "1200", eocTypeName: "Mock Assessment" },
  { status: false, result: "1300", eocTypeName: "Mock Assessment" },
]

/*
  Student CTE
*/

export interface Pathway { // legacy cte
  pathwayName: string;
  creditsRemaining: string;
  advancedCreditsRemaining: string;
  pathway: Array<{
    courseStatus: string;
    stateCourseNumber: string;
    pathwayName: string;
    courseCredit: string;
    earnedCredit: string;
    groupingId: string;
    type: string;
    courseName: string;
  }>;
}

export interface CTEClusterPathwayCourse {
  courseName: string;
  gradeEarned: string | number;
  courseTaken: string | number;
  creditEarned: number;
  courseNumber: string;
  pathwayId: number;
  sequenceId: number;
  enrolled: boolean;
}

export interface CTEClusterPathway {
  name: string;
  clusterId: number;
  progress: number;
  status: string;
  courseDetails: Array<CTEClusterPathwayCourse>;
}

export interface CTECluster {
  cteId: number;
  name: string;
  clusterAssignedCredits: number;
  totalPathwayCredits: number;
  ctePathways: Array<CTEClusterPathway>;
}

export interface StudentCTE {
  name: string;
  sequence: number;
  cteId: number;
  clusters: Array<CTECluster>;
}

export const mockStudentCtes: Array<StudentCTE> = [
  {
    "name": "CTE Programs and Pathways",
    "sequence": 0,
    "cteId": 60,
    "clusters": [
      {
        "cteId": 60,
        "name": "Business Management & Administration",
        "clusterAssignedCredits": 4,
        "ctePathways": [
          {
            "name": "Entrepreneurship",
            "clusterId": 1097,
            "progress": 2,
            "status": "Partial",
            "courseDetails": [
              {
                "courseName": "PRIN BUS MKT B",
                "gradeEarned": "89.000",
                "courseTaken": "2020",
                "creditEarned": 0.5,
                "courseNumber": "13011200",
                "pathwayId": 2282,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "PRIN BUS MKT A",
                "gradeEarned": "86.000",
                "courseTaken": "2020",
                "creditEarned": 0.5,
                "courseNumber": "13011200",
                "pathwayId": 2282,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "BUS INFO MGT 1B",
                "gradeEarned": "82.200",
                "courseTaken": "2021",
                "creditEarned": 0.5,
                "courseNumber": "13011400",
                "pathwayId": 2282,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "BUS INFO MGT 1A",
                "gradeEarned": "96.900",
                "courseTaken": "2021",
                "creditEarned": 0.5,
                "courseNumber": "13011400",
                "pathwayId": 2282,
                "sequenceId": 1,
                "enrolled": false
              }
            ]
          },
          {
            "name": "Marketing and Sales",
            "clusterId": 1097,
            "progress": 3.5,
            "status": "Scheduled",
            "courseDetails": [
              {
                "courseName": "PRIN BUS MKT B",
                "gradeEarned": "89.000",
                "courseTaken": "2020",
                "creditEarned": 0.5,
                "courseNumber": "13011200",
                "pathwayId": 2283,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "PRIN BUS MKT A",
                "gradeEarned": "86.000",
                "courseTaken": "2020",
                "creditEarned": 0.5,
                "courseNumber": "13011200",
                "pathwayId": 2283,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "BUS INFO MGT 1B",
                "gradeEarned": "82.200",
                "courseTaken": "2021",
                "creditEarned": 0.5,
                "courseNumber": "13011400",
                "pathwayId": 2283,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "BUS INFO MGT 1A",
                "gradeEarned": "96.900",
                "courseTaken": "2021",
                "creditEarned": 0.5,
                "courseNumber": "13011400",
                "pathwayId": 2283,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "BUSINESS MGT B",
                "gradeEarned": "89.000",
                "courseTaken": "2022",
                "creditEarned": 0.5,
                "courseNumber": "13012100",
                "pathwayId": 2283,
                "sequenceId": 3,
                "enrolled": false
              },
              {
                "courseName": "BUSINESS MGT A",
                "gradeEarned": "86.500",
                "courseTaken": "2022",
                "creditEarned": 0.5,
                "courseNumber": "13012100",
                "pathwayId": 2283,
                "sequenceId": 3,
                "enrolled": false
              },
              {
                "courseName": "GLOBAL BUSNS",
                "gradeEarned": "93.500",
                "courseTaken": "2023",
                "creditEarned": 0.5,
                "courseNumber": "13011800",
                "pathwayId": 2283,
                "sequenceId": 3,
                "enrolled": false
              },
              {
                "courseName": "DC HumanResourc Mgmt BMGT 1301",
                "gradeEarned": 0,
                "courseTaken": 0,
                "creditEarned": 0,
                "courseNumber": "13011900",
                "pathwayId": 2283,
                "sequenceId": 3,
                "enrolled": true
              }
            ]
          },
          {
            "name": "Business Management",
            "clusterId": 1097,
            "progress": 3,
            "status": "Partial",
            "courseDetails": [
              {
                "courseName": "PRIN BUS MKT B",
                "gradeEarned": "89.000",
                "courseTaken": "2020",
                "creditEarned": 0.5,
                "courseNumber": "13011200",
                "pathwayId": 2284,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "PRIN BUS MKT A",
                "gradeEarned": "86.000",
                "courseTaken": "2020",
                "creditEarned": 0.5,
                "courseNumber": "13011200",
                "pathwayId": 2284,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "BUS INFO MGT 1B",
                "gradeEarned": "82.200",
                "courseTaken": "2021",
                "creditEarned": 0.5,
                "courseNumber": "13011400",
                "pathwayId": 2284,
                "sequenceId": 2,
                "enrolled": false
              },
              {
                "courseName": "BUS INFO MGT 1A",
                "gradeEarned": "96.900",
                "courseTaken": "2021",
                "creditEarned": 0.5,
                "courseNumber": "13011400",
                "pathwayId": 2284,
                "sequenceId": 2,
                "enrolled": false
              },
              {
                "courseName": "BUSINESS MGT B",
                "gradeEarned": "89.000",
                "courseTaken": "2022",
                "creditEarned": 0.5,
                "courseNumber": "13012100",
                "pathwayId": 2284,
                "sequenceId": 3,
                "enrolled": false
              },
              {
                "courseName": "BUSINESS MGT A",
                "gradeEarned": "86.500",
                "courseTaken": "2022",
                "creditEarned": 0.5,
                "courseNumber": "13012100",
                "pathwayId": 2284,
                "sequenceId": 3,
                "enrolled": false
              }
            ]
          }
        ],
        "totalPathwayCredits": 3.5
      },
      {
        "cteId": 60,
        "name": "Finance",
        "clusterAssignedCredits": 4,
        "ctePathways": [
          {
            "name": "Accounting and Financial Services",
            "clusterId": 1099,
            "progress": 2,
            "status": "Partial",
            "courseDetails": [
              {
                "courseName": "PRIN BUS MKT B",
                "gradeEarned": "89.000",
                "courseTaken": "2020",
                "creditEarned": 0.5,
                "courseNumber": "13011200",
                "pathwayId": 2310,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "PRIN BUS MKT A",
                "gradeEarned": "86.000",
                "courseTaken": "2020",
                "creditEarned": 0.5,
                "courseNumber": "13011200",
                "pathwayId": 2310,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "BUS INFO MGT 1B",
                "gradeEarned": "82.200",
                "courseTaken": "2021",
                "creditEarned": 0.5,
                "courseNumber": "13011400",
                "pathwayId": 2310,
                "sequenceId": 1,
                "enrolled": false
              },
              {
                "courseName": "BUS INFO MGT 1A",
                "gradeEarned": "96.900",
                "courseTaken": "2021",
                "creditEarned": 0.5,
                "courseNumber": "13011400",
                "pathwayId": 2310,
                "sequenceId": 1,
                "enrolled": false
              }
            ]
          }
        ],
        "totalPathwayCredits": 2
      }
    ]
  },
  {
    name: "Mock CTE",
    sequence: 1,
    cteId: -1,
    clusters: [{
      cteId: -1,
      name: "Mock Cluster",
      clusterAssignedCredits: 1,
      totalPathwayCredits: 2,
      ctePathways: [{
        name: "Mock Pathway",
        clusterId: -1,
        progress: 2,
        status: "Completed",
        courseDetails: [{
          courseName: "Mock Course",
          gradeEarned: 70,
          courseTaken: "2018",
          creditEarned: 2,
          courseNumber: "01234",
          pathwayId: -1,
          sequenceId: 0,
          enrolled: true,
        },{
          courseName: "Mock Course",
          gradeEarned: 20,
          courseTaken: "2019",
          creditEarned: 2,
          courseNumber: "01234",
          pathwayId: -1,
          sequenceId: 0,
          enrolled: false,
        }]
      }]
    }]
  }
]

/*
  Student CriteriaAggregates
*/
export interface StudentCriteriaAggAssessItem {
  name: string;
  score: number;
  requiredScore: number;
  assessmentEvalType: string;
  passed: boolean;
}

export interface StudentCriteriaAggCourseItem {
  courseName: string;
  yearTaken: string;
  term: string | null;
  score: number;
  creditEarned: number;
}

export interface StudentCriteriaAggItem {
  name: string;
  lineItemId: number;
  fullDescription: string;
  status: string | null;
  criteriaRequirementsFulfilled: boolean;
  maxSampleSize: number;
  minSampleSize: number;
  required: boolean;
  type: string | null;
  lineItemBeanList: Array<StudentCriteriaAggItem>;
  acknowledgementCourseDetailBeans: Array<StudentCriteriaAggCourseItem>;
  acknowledgementAssessmentDetailBeans: Array<StudentCriteriaAggAssessItem>;
}

export interface StudentCriteriaAgg {
  name: string;
  fullDescription: string;
  type: string;
  lineItemId: number;
  status: string;
  criteriaRequirementsFulfilled: boolean;
  maxSampleSize: number;
  minSampleSize: number;
  required: boolean;
  lineItemBeanList: Array<StudentCriteriaAggItem>;
}

export const mockStudentCriteriaAgg: Array<StudentCriteriaAgg> = [
      {
        name: "Criteria Agg 1",
        fullDescription: "",
        lineItemId: 0,
        status: "Complete",
        criteriaRequirementsFulfilled: true,
        maxSampleSize: 0,
        minSampleSize: 0,
        required: false,
        type: "Assessments",
        lineItemBeanList: [
          {
            name: "Criteria Agg 1 Item",
            fullDescription: "",
            lineItemId: 1,
            status: null,
            criteriaRequirementsFulfilled: false,
            maxSampleSize: 0,
            minSampleSize: 0,
            required: false,
            type: null,
            lineItemBeanList: [],
            acknowledgementCourseDetailBeans: [],
            acknowledgementAssessmentDetailBeans: [{
              name: "Alg 1",
              score: 0.5,
              requiredScore: 1,
              assessmentEvalType: "score",
              passed: false
            }]
          }
        ]
      },
      {
        name: "Criteria Agg 2",
        fullDescription: "",
        lineItemId: 0,
        status: "Complete",
        criteriaRequirementsFulfilled: true,
        maxSampleSize: 0,
        minSampleSize: 0,
        required: false,
        type: "Not Assessments",
        lineItemBeanList: []
      },
    ];

export const mockStudentCriteriaAggWithAssessments: Array<StudentCriteriaAgg> = [
  {
    name: "Required Assessments",
    fullDescription: "",
    lineItemId: 0,
    status: "Complete",
    criteriaRequirementsFulfilled: false,
    maxSampleSize: 0,
    minSampleSize: 0,
    required: false,
    type: "Assessments",
    lineItemBeanList: [
      {
        name: "Reading Grad Req",
        fullDescription: "",
        lineItemId: 1,
        status: null,
        criteriaRequirementsFulfilled: true,
        maxSampleSize: 0,
        minSampleSize: 0,
        required: false,
        type: null,
        lineItemBeanList: [],
        acknowledgementCourseDetailBeans: [],
        acknowledgementAssessmentDetailBeans: [
          {
            name: "FSA Grade 10 ELA - English/Language Arts",
            score: 365.0,
            requiredScore: 350.0,
            assessmentEvalType: "score",
            passed: true
          },
          {
            name: "SAT EBRW - English/Language Arts",
            score: 400.0,
            requiredScore: 430.0,
            assessmentEvalType: "score",
            passed: false
          },
          {
            name: "SAT Reading - English/Language Arts",
            score: 0.0,
            requiredScore: 24.0,
            assessmentEvalType: "score",
            passed: false
          },
          {
            name: "ELA Assessment Met",
            score: 0.0,
            requiredScore: 0.0,
            assessmentEvalType: "noscore",
            passed: true
          }
        ]
      },
      {
        name: "Alg Grad Req",
        fullDescription: "",
        lineItemId: 1,
        status: null,
        criteriaRequirementsFulfilled: true,
        maxSampleSize: 0,
        minSampleSize: 0,
        required: false,
        type: null,
        lineItemBeanList: [],
        acknowledgementCourseDetailBeans: [],
        acknowledgementAssessmentDetailBeans: [
          {
            name: "FSA Algebra I EOC - Mathematics",
            score: 0.0,
            requiredScore: 497.0,
            assessmentEvalType: "score",
            passed: false
          },
          {
            name: "ACT Math - Mathematics",
            score: 19.0,
            requiredScore: 16.0,
            assessmentEvalType: "score",
            passed: true
          },
          {
            name: "ALG Assessment Passed",
            score: 0.0,
            requiredScore: 0.0,
            assessmentEvalType: "noscore",
            passed: true
          }
        ]
      },
      {
        name: "Geometry EOC",
        fullDescription: "",
        lineItemId: 1,
        status: null,
        criteriaRequirementsFulfilled: true,
        maxSampleSize: 0,
        minSampleSize: 0,
        required: false,
        type: null,
        lineItemBeanList: [],
        acknowledgementCourseDetailBeans: [],
        acknowledgementAssessmentDetailBeans: [
          {
            name: "FSA Geometry EOC - Mathematics",
            score: 504.0,
            requiredScore: 499.0,
            assessmentEvalType: "score",
            passed: true
          }
        ]
      },
      {
        name: "Biology EOC",
        fullDescription: "",
        lineItemId: 1,
        status: null,
        criteriaRequirementsFulfilled: false,
        maxSampleSize: 0,
        minSampleSize: 0,
        required: false,
        type: null,
        lineItemBeanList: [],
        acknowledgementCourseDetailBeans: [],
        acknowledgementAssessmentDetailBeans: []
      },
      {
        name: "US History EOC",
        fullDescription: "",
        lineItemId: 1,
        status: null,
        criteriaRequirementsFulfilled: true,
        maxSampleSize: 0,
        minSampleSize: 0,
        required: false,
        type: null,
        lineItemBeanList: [],
        acknowledgementCourseDetailBeans: [],
        acknowledgementAssessmentDetailBeans: [
          {
            name: "NGSSS US History EOC - Social Studies",
            score: 412.0,
            requiredScore: 397.0,
            assessmentEvalType: "score",
            passed: true
          }
        ]
      }
    ]
  },
];

/* 
  Payload definitions for slice/saga
*/

export interface StudentIDPayload {
  studentId: string;
}

export interface StudentAndGradPlanPayload {
  studentId: string;
  gradPlanId: string;
}

export interface StudentIDAndNamePayload {
  studentId: string;
  studentName: string;
}
