// @flow
/* eslint-disable */
import { PendoService } from '../interfaces/pendoService';
import { pendoSettings } from '../config';
import { PendoUserDetails } from './types';

const SCRIPT_IMPORT_ERROR = 'Error in importing Pendo script';
const INIT_SUCCESS = 'Initialized';
const COMPLETE_INIT_ERROR = 'Error in completing initialization';
const INIT_ERROR = 'Failed to initialize';
// const TRACK_ERROR = 'Error in tracking event';
const INIT_USER_SUCCESS = 'Initialized user';
const INIT_USER_ERROR = 'Failed to initialize user';
const NOT_IN_PROD = 'Not in production! Pendo will not be initialized';

export default class Pendo implements PendoService {
  name = pendoSettings.NAME;

  isInitialized = false;
  userIsInitialized = false;
  isReady = false;
  guidesLoaded = false;
  envName = '';

  winVar = {};

  log = (message: any, isError = false): void => {
    console[isError ? 'warn' : 'info'](`[${this.name}] ${message}`);
  };

  initialize = (envName: string): void => {
    this.envName = envName;
    if (this.envName !== 'production') {
      this.log(NOT_IN_PROD, false);
      return;
    }

    try {
      const { SCRIPT_ID, SCRIPT_SRC } = pendoSettings;

      const script = document.createElement('script');

      script.async = true;
      script.src = SCRIPT_SRC;
      script.id = SCRIPT_ID;

      document && document.body && document.body.appendChild(script);
    } catch (e) {
      this.log(SCRIPT_IMPORT_ERROR, true);
      this.log(e, true);

      return;
    }

    try {
      this._confirmInit();
    } catch (e) {
      this.log(COMPLETE_INIT_ERROR, true);
      this.log(e, true);
    }
  };

  _completeInit = () => {
    // eslint-disable-next-line
    // @ts-ignore
    const winVar = window.pendo

    if (!winVar) {
      this.log(INIT_ERROR + ': this.winVar empty', true);

      return;
    }

    this.winVar = winVar;

    const v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];

    for (let i = 0, x = v.length; i < x; ++i) {
      ((m: string) => {
        winVar[m] =
          winVar[m] ||
          function() {
            // eslint-disable-next-line
            winVar._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
      })(v[i]);
    }

    this.log(INIT_SUCCESS);
    this.isInitialized = true;

    // eslint-disable-next-line
    // @ts-ignore
    this.winVar.events
      .ready(() => {
        this.log('Ready');
        this.isReady = true;
      })
      .guidesLoaded(() => {
        this.log('Guides loaded.');
        this.guidesLoaded = true;
      })
      .guidesFailed(() => {
        this.log('Guides failed to load', true);
      });
  };

  _confirmInit = (_attemptNum = 0): void => {
    const attemptNum = _attemptNum + 1;

    const _confirm = () => this._confirmInit(attemptNum);

    // eslint-disable-next-line
    // @ts-ignore
    if (window.pendo) {
      try {
        this._completeInit();
      } catch (e) {
        this.log(INIT_ERROR, true);
      }
    } else {
      if (_attemptNum < pendoSettings.INIT_CONFIRM_ATTEMPT_MAX) {
        setTimeout(_confirm, 500);
      } else {
        this.log(INIT_ERROR, true);
      }
    }
  };

  initUser = (userDetails: PendoUserDetails, _attemptNum = 0): void => {
    if (this.envName !== 'production') { return }

    try {
      const attemptNum = _attemptNum + 1;

      const _initUser = () => this.initUser(userDetails, attemptNum);

      if (this.isInitialized) {
        // Special consideration is taken for some fields due to varying return
        // values. 'UNKNOWN' and 'NA' are from NullUtils.java in mesa_java.
        // We only want to append `mesa-` in some cases, and we want to place
        // `NA` instead of `mesa-NA`.

        let campusName;
        switch(userDetails.campusName) {
          case 'UNKNOWN':
          case null:
          case undefined:
          case '':
            campusName = `<unavailable>`;
            break;
          case 'NA':
            campusName = 'NA';
            break;
          default:
            campusName = `mesa-${userDetails.campusName}`;
            break;
        }

        let districtId;
        switch(userDetails.districtId) {
          case null:
          case undefined:
          case '':
          case '-1':
            districtId = `<unavailable>`;
            break;
          default:
            districtId = `mesa-districtid-${userDetails.districtId}`;
            break;
        }

        let districtName;
        switch(userDetails.districtName) {
          case 'UNKNOWN':
          case null:
          case undefined:
          case '':
            districtName = `<unavailable>`;
            break;
          default:
            districtName = userDetails.districtName;
            break;
        }


        const initData: { visitor: {}; account?: {} } = { 
          visitor: {
            id: `mesa-${userDetails.id}`,
            name: userDetails.firstName + ' ' + userDetails.lastName,
            userName: userDetails.userName,
            email: userDetails.email,
            role: `mesa-${userDetails.role}`,
            campusName: campusName
          } 
        }

        if(userDetails.districtId || userDetails.districtName){
          initData.account = {
            id: districtId,
            mesaDistrictName: districtName
          }
        }

        // eslint-disable-next-line
      // @ts-ignore
        this.winVar.initialize(initData);

        this.userIsInitialized = true;
        this.log(INIT_USER_SUCCESS);
      } else {
        if (_attemptNum < pendoSettings.TRACK_ATTEMPT_MAX) {
          setTimeout(_initUser, 500);
        } else {
          this.log(INIT_USER_ERROR, true);
        }
      }
    } catch (e) {
      this.log(INIT_USER_ERROR, true);
      this.log(e, true);
    }
  };

  // _trackEvent = ({ name, defaultMetadata }: PendoEvent, metadata: Metadata = {}): void => {
  //   if (!this.userIsInitialized) {
  //     this.log(`Failed to track action ${name}: User not initialized.`, true);

  //     return;
  //   }

  //   try {
  //     this.winVar.track(name, { defaultMetadata, ...metadata });
  //   } catch (e) {
  //     this.log(TRACK_ERROR, true);
  //     this.log(e, true);
  //   }
  // };

  // trackClick = (metadata: Metadata = {}): void => {
  //   this._trackEvent(PendoEventEnum.CLICK, metadata);
  // };
}
/* eslint-enable */
