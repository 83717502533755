
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useToggle, useClickOutside } from '../../../mesacloud/react-framework'

import { useSelector, fileExportSagaActions } from '../../../store'

import HeaderExportsOption from './HeaderExportsOption'

const HeaderExports = () => {

  const [showOptions, toggleShowOptions] = useToggle(false)

  const clickRef = useRef(null)
  useClickOutside(clickRef, () => toggleShowOptions(false))

  const {
    isExportingCourses,
    isExportingOffTrack,
    isExportingOnTrack
  } = useSelector(({ fileExport }) => fileExport)

  const dispatch = useDispatch()

  const exportCourses = () => dispatch(fileExportSagaActions.fetchCoursesExport())
  const exportOnTrack = () => dispatch(fileExportSagaActions.fetchOnTrackExport())
  const exportOffTrack = () => dispatch(fileExportSagaActions.fetchOffTrackExport())

  return (
    <div ref={clickRef}>
      <div className="header__item exports no-mobile" onClick={toggleShowOptions}>
        <i className="fas fa-download"/> 
        <span>Export</span> 
        <i className={showOptions ? "fas fa-caret-up" : "fas fa-caret-down"}/>
      </div>
      {showOptions && (
        <ul className="header__exports-list">
          <HeaderExportsOption isLoading={isExportingCourses} displayText="Courses" exportFunc={exportCourses}/>
          <HeaderExportsOption isLoading={isExportingOnTrack} displayText="On Track" exportFunc={exportOnTrack}/>
          <HeaderExportsOption isLoading={isExportingOffTrack} displayText="Off Track Seniors" exportFunc={exportOffTrack}/>
        </ul>
      )}
    </div>
  )

}

export default HeaderExports
