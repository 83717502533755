
import React from 'react'

import { useConfigurableStyle } from '../_utils/useConfigurableStyle'

import {
  HasEditableClassName,
  MaybeHasChildren,
  ExtendableProps
} from '../propsInterfaces'

interface PanelHeaderPropTypes extends
  HasEditableClassName,
  MaybeHasChildren, 
  ExtendableProps {
}

const PanelHeader = ({
  className,
  overrideDefaultClassName,
  children,
  ...props
}: PanelHeaderPropTypes) => {

  const [finalClassName] = useConfigurableStyle({
    defaultClassName: "panel__view__header",
    additionalClassName: className, 
    overrideDefaultClassName, 
  })

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  )
}

export default PanelHeader