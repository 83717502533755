import { RefObject, useEffect, useMemo, useRef } from "react"

/*

  useRefListener

  Ex: const Component = () => {
    const clickRef = useRef(null)

    useRefListener(clickRef, "click", (e) => { handleClick(e) })

    return <div ref={clickRef}/>
  }

  Usage: 
    useRefListener(ref: React ref, eventName: string, callback: Function)

  Sets an event listener on element

*/

export const useRefListener = (ref: RefObject<HTMLElement>, eventName: string, callback: (e: Event) => any) => {
  const { current } = ref;

  const _current = useMemo(() => current, [current]);
  const _eventName = useMemo(() => eventName, [eventName]);
  const _callback = useMemo(() => callback, [callback])

  const prevCallback = useRef(callback)

  useEffect(() => {
    if(_callback !== prevCallback.current){
      _current && _current.removeEventListener(_eventName, prevCallback.current, false)
      prevCallback.current = _callback
    }
    _current && _current.addEventListener(_eventName, _callback, false)
  }, [_current, _eventName, _callback]);

  useEffect(() => {
    return () => ref?.current?.removeEventListener(_eventName, prevCallback.current, false)
  }, [_eventName])
}