
import { Enum } from '../../mesacloud/corejs'

export interface PanelView {
  keyname: string;
  displayTitle: string;
  useMask: boolean;
}

export class PanelViewEnum extends Enum implements PanelView {

  public static FILTER = new PanelViewEnum("filter_panel", "Filter", true)
  public static SUPPORT_REQUEST = new PanelViewEnum("support_request_panel", "Support Request", true)
  public static GROUP_LIST = new PanelViewEnum("group_list_panel", "Group", false)
  public static NOTES = new PanelViewEnum("notes_panel", "Notes", true)
  public static NA = new PanelViewEnum("na_panel", "N/A (Error)", true)

  protected constructor(public keyname: string, public displayTitle: string, public useMask: boolean) { super() }

  public static asArray(){
    return super.asArray() as Array<PanelViewEnum>
  }

  public static asObject(){
    return super.asObject() as { [name: string]: PanelViewEnum }
  }
}
