
import React from 'react'
import MesaUI, { useDocumentTitle } from "../../mesacloud/react-framework"

import { useCloseNotesOnFirstLoad } from '../../hooks'
import { viewActions } from '../../store'
import { PanelViewEnum, UserRoleEnum } from '../../lib/enums'

import RoleFilter from '../../components/RoleFilter'
import { useDispatch } from 'react-redux'

const Resources = () => {

  useDocumentTitle("Mesa OnTime: Additional Resources")

  useCloseNotesOnFirstLoad()

  const dispatch = useDispatch()

  const openSupportRequest = () => {
    dispatch(viewActions.setPanelView({ view: PanelViewEnum.SUPPORT_REQUEST }))
    dispatch(viewActions.openPanel())
  }

  return (
    <div className="resources">
      <MesaUI.Container className="resources__banner">
        <MesaUI.TopographyBanner/>
        <h2>Have no fear.  We&apos;re here to help.</h2>
      </MesaUI.Container>
      <MesaUI.Container className="resources__question">
        <h3>Have a question about Mesa?</h3>
        <p>Make a <span onClick={openSupportRequest}>Support Request</span>, or email us at <a href="mailto:support@mesaedu.com">support@mesaedu.com</a>!</p>
      </MesaUI.Container>
      <MesaUI.Container className="resources__knowledge">
        <h3>Explore the Knowledge Base</h3>
        <MesaUI.Container className="resources__links">
          <RoleFilter roles={[UserRoleEnum.STUDENT]} exclude>
            <a className="resources__link" href="https://intercom.help/mesaedu/school-counselors" rel="noopener noreferrer" target="_blank">
              <div className="resources__link__card">
                <i className="far fa-clipboard"/>
                <div>
                  <div>I am a</div>
                  <div>School Counselor</div>
                </div>
              </div>
            </a>
            <a className="resources__link" href="https://intercom.help/mesaedu/school-administrators" rel="noopener noreferrer" target="_blank">
              <div className="resources__link__card">
                <i className="fas fa-university"/>
                <div>
                  <div>I am an</div>
                  <div>Administrator</div>
                </div>
              </div>
            </a>
          </RoleFilter>
          <a className="resources__link" href="https://intercom.help/mesaedu/students" rel="noopener noreferrer" target="_blank">
            <div className="resources__link__card">
              <i className="fas fa-building"/>
              <div>
                <div>I am a</div>
                <div>Student</div>
              </div>
            </div>
          </a>
          <RoleFilter roles={[UserRoleEnum.STUDENT]} exclude>
            <a className="resources__link" href="https://intercom.help/mesaedu/it-specialists" rel="noopener noreferrer" target="_blank">
              <div className="resources__link__card">
                <i className="fas fa-sitemap"/>
                <div>
                  <div>I am an</div>
                  <div>IT Specialist</div>
                </div>
              </div>
            </a>
          </RoleFilter>
        </MesaUI.Container>
      </MesaUI.Container>
      <MesaUI.Container className="resources__browse">
        <a className="resources__link" href="https://intercom.help/mesaedu" rel="noopener noreferrer" target="_blank">
          Browse All Topics
        </a>
      </MesaUI.Container>
      <MesaUI.Container className="resources__address">
        <div>Mesa Cloud</div>
        <div>3801 Berkman Dr A</div>
        <div>Austin, TX 78723</div>
        <div>United States</div>
      </MesaUI.Container>
    </div>
  )
}

export default Resources
