import createClassName from 'classnames'
import { MesaColor } from '../../../corejs'

import { ClassNamesArg, useEditableClassName } from './useEditableClassName'
import { useMarginAndPaddingStyle } from './useMarginAndPaddingStyle'
import { useColorStyle } from './useColorStyle'

import { MarginOrPaddingProp } from '../propsInterfaces'

export const useConfigurableStyle = ({
  defaultClassName,
  additionalClassName,
  overrideDefaultClassName,
  marginType,
  paddingType,
  backgroundColor,
  fontColor,
  borderColor,
  styleProp
}: {
  defaultClassName: ClassNamesArg;
  additionalClassName?: ClassNamesArg;
  overrideDefaultClassName?: boolean | null;
  marginType?: MarginOrPaddingProp;
  paddingType?: MarginOrPaddingProp;
  backgroundColor?: MesaColor | string;
  fontColor?: MesaColor | string;
  borderColor?: MesaColor | string;
  styleProp?: { [styleAttr: string]: any };
}): [string, { [styleAttr: string]: any }] => {

  const _style: { [styleAttr: string]: any } = (styleProp && typeof styleProp === "object") ? styleProp : {}

  const [marginPaddingClassNames, marginPaddingStyle] = useMarginAndPaddingStyle({ margin: marginType, padding: paddingType })
  const [colorClassNames, colorStyle] = useColorStyle({ backgroundColor, fontColor, borderColor})
  const editedClassName = useEditableClassName(defaultClassName, additionalClassName, overrideDefaultClassName)

  const mergedClassName = createClassName(editedClassName, marginPaddingClassNames, colorClassNames)
  const style = { ...marginPaddingStyle, ...colorStyle, ..._style }

  return [mergedClassName, style]
}
