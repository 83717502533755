import { useRef } from "react";
import { v4 as uuid } from 'uuid';
/* 
  useUuid

  Example: const Component = (props) => { 
    componentId = useUuid()

    return <div>{props.arr.map((thing, i) => <div key={componentId + "-item-" + i})>{thing}</div>}
  }

  Usage:
    useUuid(void)

  Generates uuid to be used by an instance of a Component that lives with the component while it's mounted.
*/

export const useUuid = (): string => {
  // use of useRef allows to initialize the value once immediately with no change on updates

  const ref = useRef<string>("");

  if (!ref.current) {
    ref.current = uuid();
  }

  return ref.current;
};
